<div class="wrapper">
    <!-- <app-error-model
        message="{{ eMessage }}"
        [display]="display"
        (event)="closeModel($event)"></app-error-model>
    <app-success-model
        message="{{ sMessage }}"
        [display]="displaySuccess"
        (event)="closeModel($event)"></app-success-model> -->
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <app-header title="Transfer To Bank"></app-header>
        <app-transfer-to-bank-links></app-transfer-to-bank-links>
        <div>
            <section class="add-section">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-md-12 section-heading">Search</div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-md-6">
                            <label class="form-label">SEARCH BY SENDER OR BUSINESS</label>
                            <div class="input-icons">
                                <i class="fa fa-search icon" aria-hidden="true"></i>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="eg. Zakihiti’s Spaza"
                                    formControlName="text" />
                                <a *ngIf="form.get('text').value.length > 0" (click)="clearText()">
                                    <img src="assets/images/close-x-purple.png" />
                                </a>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label"></label>
                            <div>
                                <button class="search-button" (click)="submit()">Search</button>
                                <a class="advanced-search" (click)="toggleOption()">
                                    <img src="assets/images/dropdown.png" />
                                    <u>ADVANCED SEARCH</u>
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="showOptions" />
                    <div class="search-form" *ngIf="showOptions">
                        <div class="row">
                            <div class="col-md-4">
                                <label class="form-label">TRANSACTION STATUS</label>
                                <div>
                                    <app-select-box
                                        placeholderValue="Select Status"
                                        [form]="form"
                                        value="status"
                                        [dropDown]="transactionStatusDropdown">
                                    </app-select-box>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label class="form-label">ACCOUNT TYPE</label>
                                <div>
                                    <app-select-box
                                        placeholderValue="Select Type"
                                        [form]="form"
                                        value="role"
                                        [dropDown]="accountTypeDropdown">
                                    </app-select-box>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="searched-items">
                        <div *ngIf="searchItems.length > 0">
                            <div *ngFor="let item of searchItems" class="search-item-value">
                                {{ item | humanize }}
                                <a (click)="removeSearchItem(item)">
                                    <img src="assets/images/close-x-white.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
            <div class="progress-container" *ngIf="isLoading">
                <div class="progress-container-text">
                    <div>Searching...</div>
                </div>
                <div>
                    <div class="custom-progress-bar">
                        <span [style.width.%]="progressWidth"></span>
                    </div>
                </div>
            </div>
            <section *ngIf="search">
                <div class="search-result-header">
                    Search Results: {{ transactionList.length }} of {{ pagination.total }}
                </div>
                <hr />
                <div class="container-fluid tab-pane custom-table custom-last-row">
                    <br />
                    <table id="tableData">
                        <thead>
                            <tr>
                                <th width="30.5%">
                                    <app-filter-arrow
                                        filter="fromUserName"
                                        title="USER OR BUSINESS NAME"
                                        (filterValue)="receiveFilter($event)">
                                    </app-filter-arrow>
                                </th>
                                <th>
                                    <app-filter-arrow
                                        filter="fromRole"
                                        title="USER TYPE"
                                        (filterValue)="receiveFilter($event)">
                                    </app-filter-arrow>
                                </th>
                                <th>
                                    <app-filter-arrow
                                        filter="receivedAt"
                                        title="RECEIVED"
                                        (filterValue)="receiveFilter($event)">
                                    </app-filter-arrow>
                                </th>
                                <th>
                                    <app-filter-arrow
                                        filter="status"
                                        title=" STATUS"
                                        (filterValue)="receiveFilter($event)">
                                    </app-filter-arrow>
                                </th>
                                <th>
                                    <app-filter-arrow
                                        filter="amount"
                                        title=" AMOUNT"
                                        (filterValue)="receiveFilter($event)">
                                    </app-filter-arrow>
                                </th>
                                <th>
                                    <app-filter-arrow title="ACTIONS"> </app-filter-arrow>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="tablebox" *ngFor="let tran of transactionList">
                                <td width="30%">
                                    <img
                                        class="table-user-img"
                                        src="{{
                                            tran.profilePicture || 'assets/images/placeholder.png'
                                        }}" />
                                    <a (click)="commonUtil.goToSenderProfile(tran)">
                                        <strong
                                            ><u
                                                ><span>{{ tran.fromUserName }}</span></u
                                            ></strong
                                        >
                                    </a>
                                </td>
                                <td>{{ tran.fromRole | humanize }}</td>
                                <td>{{ tran.receivedAt | date: 'd/M/yyyy - h:mm a' }}</td>
                                <td>
                                    <app-account-status
                                        type="transaction"
                                        transactionStatus="{{ tran.status }}">
                                    </app-account-status>
                                </td>
                                <td>{{ tran.amount }}</td>
                                <td>
                                    <app-last-child-admin-ttb
                                        (event)="action($event)"
                                        [transaction]="tran"
                                        [netcashEnabled]="netcashEnabled"
                                        [processed]="tran.processing">
                                    </app-last-child-admin-ttb>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="pagination.showPagination()">
                            <tr>
                                <td>
                                    Rows per page:
                                    <span
                                        ><b>{{ pagination.length }}</b></span
                                    >
                                    <app-page-length-dropdown (emitLength)="changeLength($event)">
                                    </app-page-length-dropdown>
                                </td>
                                <td>
                                    <app-page-navigation
                                        pageNumber="{{ pagination.getPageNo() }}"
                                        totalPages="{{ pagination.getTotalPages() }}"
                                        (changePage)="changePage($event)">
                                    </app-page-navigation>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </section>
            <ng-container *ngIf="displayView">
                <app-transaction-view-modal
                    [transaction]="transaction"
                    [transferToBank]="true"
                    (event)="closeViewModel()">
                </app-transaction-view-modal>
            </ng-container>

            <app-modal
                modalId="{{ ttbUpdateModalId }}"
                buttonConfirmText="{{ netcashEnabled ? 'Update' : 'Process' }}"
                buttonCancelText="Close"
                (modalEventConfirm)="netcashEnabled ? update() : processWithoutNetcash()"
                (modalEventCancel)="resetUpdateForm()">
                <div class="section-heading mb-3">
                    @if (netcashEnabled) {
                        Update Bank Transfer Status
                    } @else {
                        Process Transfer to Bank
                    }
                </div>
                <div>
                    Transfer to Bank request from:<br />
                    {{ transaction.fromUserName }}
                </div>

                <div class="mt-3">
                    Amount: {{ transaction.amount | currency: 'ZAR' : 'symbol-narrow' }}
                </div>

                @if (netcashEnabled) {
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <label class="form-label">SELECT STATUS*</label>
                            <app-select-box
                                placeholderValue="Set Status"
                                value="status"
                                [form]="updateForm"
                                [dropDown]="updateStatusDropdown">
                            </app-select-box>
                        </div>
                    </div>
                }

                <form [formGroup]="updateForm">
                    <div class="row mt-4">
                        <div class="col-md-12">
                            <label for="manualChangeReason" class="form-label required">NOTE</label>
                            <textarea
                                id="manualChangeReason"
                                class="form-input"
                                formControlName="manualChangeReason"
                                [ngClass]="
                                    formUtils.displayFieldCss(updateForm, 'manualChangeReason')
                                ">
                            </textarea>
                            <app-field-error-display
                                [displayError]="
                                    formUtils.isFieldInValid(updateForm, 'manualChangeReason')
                                "
                                errorMsg="Note is required">
                            </app-field-error-display>
                        </div>
                    </div>

                    @if (!netcashEnabled) {
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <button
                                    type="submit"
                                    class="transaction-details-custombtn background-inherit text-orange border-orange"
                                    (click)="update()">
                                    Cancel This Request
                                </button>
                            </div>
                        </div>
                    }
                </form>
            </app-modal>
        </div>
    </div>
    <div class="control-sidebar-bg"></div>
</div>
