<div class="edit-link">
    <a data-toggle="dropdown">
        <div class="dot-icon"></div>
    </a>
    <ul class="dropdown-menu" aria-labelledby="menu1">
        <li>
            <a class="dropdown-menu-item" (click)="navigate()">
                <img src="assets/images/transactions.png" />
                View Transactions
            </a>
        </li>
        <li>
            <a class="dropdown-menu-item" (click)="update('update')">
                <img src="assets/images/approve-application.png" />
                View/Edit Details
            </a>
        </li>
        <li>
            <a class="dropdown-menu-item" (click)="update('deactivate')">
                <img src="assets/images/deny-application.png" />
                Deactivate
            </a>
        </li>
    </ul>
</div>
