import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { URI } from 'src/app/settings/uri-constants';
import { StorageConstant } from 'src/app/settings/storage-constants';
import { Employee } from 'src/app/model/employee';

@Component({
    selector: 'app-last-child-enterprise',
    templateUrl: './last-child-enterprise.component.html',
    styleUrls: ['./last-child-enterprise.component.css'],
})
export class LastChildEnterpriseComponent {
    urlTransaction: string;
    @Input() user: Employee;
    @Output() event = new EventEmitter<{ type: string; user: Employee }>();

    constructor(private router: Router) {}

    navigate() {
        localStorage.setItem(StorageConstant.EMPLOYEE_NUMBER, JSON.stringify(this.user));
        this.router.navigate([URI.SLASHED_ENTERPRISE_TRANSACTION]);
    }

    update(type: string) {
        this.event.emit({ type: type, user: this.user });
    }
}
