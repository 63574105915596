<!-- Left side column. contains the sidebar -->
<aside class="main-sidebar">
    <!-- Logo -->
    <a class="logo">
        <!-- mini logo for sidebar mini 50x50 pixels -->
        <span class="logo-mini"><img src="assets/images/logo-icon.png" /></span>
        <!-- logo for regular state and mobile devices -->
        <span class="logo-lg"
            ><img src="assets/images/{{ companyService.getSelectedCompanyCode() }}-logo-light.png"
        /></span>
    </a>
    <section class="sidebar">
        <!-- sidebar menu: : style can be found in sidebar.less -->
        <ul class="sidebar-menu" data-widget="tree">
            <!-- <li routerLinkActive="active-sidebar">
                <a
                    routerLink="{{ uri.SLASHED_ENTERPRISE_DASHBOARD }}"
                    routerLinkActive="active-sidebar-anchor active">
                    <div class="dashboard-image-state"></div>
                    <span>Dashboard</span>
                </a>
            </li> -->
            <li routerLinkActive="active-sidebar">
                <a
                    routerLink="{{ uri.SLASHED_ENTERPRISE_PROFILE }}"
                    routerLinkActive="active-sidebar-anchor active">
                    <div class="user-image-state"></div>
                    <span>Profile</span>
                </a>
            </li>
            <!-- <li routerLinkActive="active-sidebar">
                <a
                    routerLink="{{ uri.SLASHED_ENTERPRISE_CASHBACK_SETTINGS }}"
                    routerLinkActive="active-sidebar-anchor active">
                    <div class="cashback-settings-image-state"></div>
                    <span>Cashback Settings</span>
                </a>
            </li> -->
            <li routerLinkActive="active-sidebar">
                <a
                    routerLink="{{ uri.SLASHED_ENTERPRISE_TRANSFER_TO_BANK }}"
                    routerLinkActive="active-sidebar-anchor active">
                    <div class="ttb-image-state"></div>
                    <span>Transfer To Bank</span>
                </a>
            </li>
            <!-- <li routerLinkActive="active-sidebar">
                <a
                    routerLink="{{ uri.SLASHED_ENTERPRISE_TRANSACTION }}"
                    routerLinkActive="active-sidebar-anchor active">
                    <div class="transaction-image-state"></div>
                    <span>Transactions</span>
                </a>
            </li> -->
            <li routerLinkActive="active-sidebar">
                <a
                    routerLink="{{ uri.getRouteNavPath('enterpriseCashbacksRedeemed') }}"
                    routerLinkActive="active-sidebar-anchor active">
                    <div class="transaction-image-state"></div>
                    <span>Cashbacks Redeemed</span>
                </a>
            </li>
        </ul>
    </section>
    <section class="sidebar-footer">
        <div>{{ name }}</div>
        <div class="sidebar-menu-footer" (mouseenter)="mouseEnter()" (mouseleave)="mouseEnter()">
            <a (click)="logout()">
                <img src="assets/images/{{ iconName }}.png" />
                <span><u>Logout</u></span>
            </a>
        </div>
        <div class="sidebar-footer-copyright">All Rights Reserved © Lov.Cash</div>
        <div class="sidebar-footer-compressed">©</div>
    </section>
    <!-- /.sidebar -->
</aside>
<div id="logoutModal" class="image-modal" [style.display]="logoutModalDisplay">
    <div class="logout-window">
        <div class="section-heading mb-1">Are you sure you want to Logout?</div>
        <div>
            <button type="submit" class="box-outline submit-btn mb-2" (click)="submit()">
                Yes, Log Out
            </button>
            <a class="deny-window-close" (click)="closeModal()"><u>CANCEL</u></a>
        </div>
    </div>
</div>
