import { Bank } from './bank';
import { Deserializable } from './deserializable';
import { PrimaryContact } from './primary.contact';
import { Supplier } from './supplier';
import { UserAddress } from './user.address';

export class Enterprise implements Deserializable {
    public id: string;
    public userId: string;
    public roleId: string;
    public userRoleId: string;
    public businessName: string;
    public employeeName: string;
    public enterpriseId: string;
    public enterpriseEmployeeId: string;
    public status: string;
    public kycLevel: string;
    public createdAt: string;
    public businessLogo: string;
    public lastLogin: string;
    public availableBalance: string;
    public fullBalance: string;
    public publicKey: string;
    public code: string;
    public visibleInPayList: boolean;
    public directDistributor: boolean;
    public isWholesaler: boolean;
    public bank: Bank = new Bank();
    public primaryContact: PrimaryContact = new PrimaryContact();
    public supplier: Supplier;
    public address: UserAddress;

    public isSupplier() {
        return this.supplier?.active;
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

