<div class="wrapper">
    <app-error-model
        message="{{ eMessage }}"
        [display]="display"
        (event)="closeModel($event)"></app-error-model>
    <app-success-model
        message="{{ sMessage }}"
        [display]="displaySuccess"
        (event)="closeModel($event)"></app-success-model>
    <app-navigation *ngIf="loginService.isAdmin()"></app-navigation>
    <app-enterprise-navigation *ngIf="loginService.isEnterprise()"></app-enterprise-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <app-header location="User Account Profile"></app-header>
        <app-enterprise-links [id]="id" [isAdmin]="loginService.isAdmin()"></app-enterprise-links>
        <section class="add-section" *ngIf="enterprise.code">
            <a class="text-danger" (click)="resendLink()"
                ><u>Resend Create Password Email Link</u></a
            >
        </section>
        <section class="add-section">
            <div class="row">
                <div class="col-md-8 section-heading">Enterprise Settings</div>
                <div class="col-md-4 add-section-heading">
                    <app-edit-pencil (edit)="changeView($event, 'enterprise')"></app-edit-pencil>
                </div>
            </div>
            <div *ngIf="!editEnterprise">
                <div class="row">
                    <div class="col-md-3">
                        <label class="form-label">ACCOUNT STATUS</label>
                        <div class="user-details-text">
                            {{ enterprise.status || '-' | humanize }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">KYC LEVEL</label>
                        <div class="user-details-text">
                            {{ enterprise.kycLevel || '-' | humanize }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">ADD ACCOUNT TO PAY LIST</label>
                        <div class="user-details-text">
                            {{ enterprise.visibleInPayList ? 'YES' : 'No' || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">DIRECT DISTRIBUTOR</label>
                        <div class="user-details-text">
                            {{ enterprise.directDistributor ? 'YES' : 'No' || '-' }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="form-label">WHOLESALER</label>
                        <div class="user-details-text">
                            {{ enterprise.isWholesaler ? 'YES' : 'No' || '-' }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">SUPPLEIR</label>
                        <div class="user-details-text">
                            {{ enterprise.isSupplier() ? 'YES' : 'No' || '-' }}
                        </div>
                    </div>
                </div>
                <section class="mt-4" *ngIf="enterpriseFeeList.length > 0">
                    <app-enterprise-fees
                        title="Fees Paid by Enterprise User"
                        [showEditPencil]="false"
                        [parentToUpdate]="true"
                        [editEnterprise]="editEnterprise"
                        [enterpriseFeeList]="enterpriseFeeList"
                        (updateFee)="submit()">
                    </app-enterprise-fees>
                </section>
            </div>
            <div *ngIf="editEnterprise">
                <div class="row">
                    <div class="col-md-3">
                        <label class="form-label">ACCOUNT STATUS</label>
                        <app-select-box
                            placeholderValue="Set Status"
                            value="enterpriseStatus"
                            [form]="form"
                            [invalidForm]="invalidForm"
                            [dropDown]="statusDropdown">
                        </app-select-box>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">KYC LEVEL</label>
                        <app-select-box
                            placeholderValue="Set Kyc Level"
                            value="kycLevel"
                            [form]="form"
                            [invalidForm]="invalidForm"
                            [dropDown]="kycLevelDropdown">
                        </app-select-box>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label pr-3">ADD ACCOUNT TO PAY LIST</label>
                        <br />
                        <label class="switch-container">
                            <input
                                type="checkbox"
                                (change)="changeStatus()"
                                [checked]="isVisible" />
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label pr-3">DIRECT DISTRIBUTOR</label>
                        <br />
                        <label class="switch-container">
                            <input
                                type="checkbox"
                                (change)="changeDirectDistributor()"
                                [checked]="isDirectDistributor" />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="form-label pr-3">WHOELSALER</label>
                        <br />
                        <label class="switch-container">
                            <input
                                type="checkbox"
                                (change)="changeIsWholesaler()"
                                [checked]="isWholesaler" />
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label pr-3">SUPPLIER</label>
                        <br />
                        <label class="switch-container">
                            <input
                                type="checkbox"
                                (change)="changeIsSupplier()"
                                [checked]="isSupplier" />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
                <section class="mt-4" *ngIf="enterpriseFeeList.length > 0">
                    <app-enterprise-fees
                        title="Fees Paid by Enterprise User"
                        [showEditPencil]="false"
                        [parentToUpdate]="true"
                        [editEnterprise]="editEnterprise"
                        [enterpriseFeeList]="enterpriseFeeList"
                        (updateFee)="submit()">
                    </app-enterprise-fees>
                </section>
                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <button class="custombtn custombtn-align-right" (click)="submit()">
                            SAVE
                        </button>
                        <a class="user-files-deny" (click)="changeView($event, 'enterprise')"
                            ><u>CANCEL</u></a
                        >
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
