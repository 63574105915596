import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICashbacksRedeemed } from '../model/cashbacks.redeemed';
import { GenericResponse } from '../model/generic.response';
import { AppSettings } from '../settings/app-settings';
import { ICashbackReport } from './../model/cashback.report';

@Injectable({ providedIn: 'root' })
export class CashbackReportService {
    constructor(
        private http: HttpClient
    ) { }

    getCashbackReports(queryParams: any) {
        const params = new HttpParams({ fromObject: queryParams });
        return this.http.get<GenericResponse<ICashbackReport[]>>(
            AppSettings.API_ENDPOINT + AppSettings.GET_CASHBACK_REPORTS,
            { params },
        );
    }

    getCashbackRedeemed(enterpriseId: string, queryParams: any) {
        const params = new HttpParams({ fromObject: queryParams });
        const path = AppSettings.GET_WHOLESALER_CASHBACK_REDEEMED.replace('@1@', enterpriseId);

        return this.http.get<GenericResponse<ICashbacksRedeemed[]>>(
            AppSettings.API_ENDPOINT + path,
            { params }
        );
    }
}
