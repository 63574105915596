import { Component } from '@angular/core';

import { CompanyService } from 'src/app/services/company.service';
import { LoginService } from 'src/app/services/login.service';
import { URI } from 'src/app/settings/uri-constants';

@Component({
    selector: 'app-enterprise-navigation',
    templateUrl: './enterprise-navigation.component.html',
    styleUrls: ['./enterprise-navigation.component.css'],
})
export class EnterpriseNavigationComponent {
    iconName: string;
    logoutModalDisplay = 'none';
    name: string;
    uri = URI;

    constructor(
        private loginService: LoginService,
        public companyService: CompanyService,
    ) {
        this.iconName = 'logout-icon';
        this.name = this.loginService.sessionUser.value.name;
    }

    mouseEnter() {
        this.iconName = this.iconName === 'logout-icon' ? 'logout-hover' : 'logout-icon';
    }

    logout() {
        this.logoutModalDisplay = 'block';
    }

    closeModal() {
        this.logoutModalDisplay = 'none';
    }

    submit() {
        this.loginService.logout();
    }
}
