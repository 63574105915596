import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Role } from 'src/app/enums/role';
import { Employee } from 'src/app/model/employee';
import { Pagination } from 'src/app/model/Pagination';
import { CommonService } from 'src/app/services/common.service';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { LoginService } from 'src/app/services/login.service';
import { MessageConstant } from 'src/app/settings/message.constant';
import { FormUtils } from 'src/app/utils/form.utils';

@Component({
    selector: 'app-enterprise-employees',
    templateUrl: './enterprise-employees.component.html',
    styleUrls: ['./enterprise-employees.component.css'],
})
export class EnterpriseEmployeesComponent implements OnInit {
    form: FormGroup;
    employeeForm: FormGroup;
    employeeUpdateForm: FormGroup;
    employeePinUpdateForm: FormGroup;
    showOptions = false;
    invalidForm = false;
    invalidUpdateForm = false;
    invalidPinUpdateForm = false;
    userList: Array<Employee>;
    pagination = new Pagination();
    eMessage = '';
    sMessage = '';
    isLoading = false;
    search = false;
    display = false;
    displaySuccess = false;
    displayForm = 'none';
    displayUpdateForm = 'none';
    displayPinUpdateForm = 'none';
    displayPin = 'none';
    progressWidth = 25;
    id: string;
    pin: string;
    authMessage: string;
    modelType: string;
    employee: Employee = new Employee();
    role = Role;
    searchItems: string[] = [];
    itemList = ['store', 'status'];
    statusDropdown: string[] = [];
    formUtil = FormUtils;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private commonService: CommonService,
        public loginService: LoginService,
        private enterpriseService: EnterpriseService,
    ) {
        this.id = this.loginService.sessionUser.value.enterprise.enterpriseId;

        this.form = this.formBuilder.group({
            text: [''],
            start: [''],
            length: [''],
            store: [''],
            status: [''],
        });

        this.setCreateForm();
        this.employeeUpdateForm = this.formBuilder.group({
            store: ['', [Validators.required, Validators.maxLength(35)]],
            isManager: ['false'],
            enterpriseStaffId: [''],
        });

        this.employeePinUpdateForm = this.formBuilder.group({
            pin: ['', [Validators.required, Validators.maxLength(4), Validators.minLength(4)]],
            confirmPin: [
                '',
                [Validators.required, Validators.maxLength(4), Validators.minLength(4)],
            ],
            userName: [''],
        });
    }

    ngOnInit() {
        this.commonService.getUserStatus().subscribe((res) => {
            this.statusDropdown = res.data;
        });

        this.getData();
    }

    getData() {
        this.search = false;
        this.isLoading = true;
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);
        this.setProgressWidth(50);
        this.enterpriseService.getEnterpriseEmployees(this.form, this.id).subscribe(
            (user) => {
                this.setProgressWidth(100);
                setTimeout(() => {
                    this.userList = user.data;
                    this.pagination.total = user.total || 0;
                    this.isLoading = false;
                    this.search = true;
                    this.progressWidth = 25;
                }, 350);

                this.searchItems = [];
                for (const item of this.itemList) {
                    if (this.form.get(item).value !== '') {
                        this.searchItems.push(this.form.get(item).value);
                    }
                }
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    setCreateForm() {
        this.employeeForm = this.formBuilder.group({
            firstName: ['', [Validators.required, Validators.maxLength(35)]],
            lastName: ['', [Validators.required, Validators.maxLength(35)]],
            store: ['', [Validators.required, Validators.maxLength(35)]],
            pin: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
            isManager: ['false'],
            enterpriseId: [this.id],
            role: ['enterprise_staff'],
        });
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    searchUsers(text: string) {
        this.pagination.text = text;
        this.getData();
    }

    clearText() {
        this.form.get('text').setValue('');
    }

    receiveFilter(data: { name: string; value: number }) { }

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    removeSearchItem(value: string) {
        for (const item of this.itemList) {
            if (this.form.get(item).value === value) {
                this.form.get(item).setValue('');
                this.getData();
                break;
            }
        }
    }

    toggleOption() {
        this.showOptions = !this.showOptions;
    }

    get controls(): any {
        return this.form.controls;
    }

    closeModel(event: any) {
        this.display = false;
        this.displaySuccess = false;
    }

    submit() {
        this.getData();
    }

    openForm() {
        this.displayForm = 'block';
    }

    closeForm(reset = false) {
        this.displayForm = 'none';
        this.invalidForm = false;

        if (reset) {
            this.setCreateForm();
        }
    }

    openUpdateForm() {
        this.employeeUpdateForm.get('store').setValue(this.employee.store);
        this.employeeUpdateForm.get('isManager').setValue(String(this.employee.isManager));
        this.employeeUpdateForm
            .get('enterpriseStaffId')
            .setValue(String(this.employee.enterpriseStaffId));
        this.displayUpdateForm = 'block';
    }

    openPinUpdateForm() {
        this.closeUpdateForm();
        this.employeePinUpdateForm.get('pin').setValue('');
        this.employeePinUpdateForm.get('confirmPin').setValue('');
        this.employeePinUpdateForm.get('userName').setValue(this.employee.employeeNumber);
        this.displayPinUpdateForm = 'block';
    }

    closeUpdateForm() {
        this.displayUpdateForm = 'none';
        this.invalidUpdateForm = false;
    }

    closePinUpdateForm() {
        this.displayUpdateForm = 'none';
        this.invalidUpdateForm = false;
        this.displayPinUpdateForm = 'none';
        this.invalidPinUpdateForm = false;
    }

    openPinModel() {
        this.displayPin = 'block';
    }

    closePinModel() {
        this.displayPin = 'none';
    }

    validPin(): boolean {
        if (
            this.employeePinUpdateForm.get('pin').value !==
            this.employeePinUpdateForm.get('confirmPin').value
        ) {
            return false;
        }
        return true;
    }

    authenticate() {
        this.closePinModel();
        this.isLoading = true;
        this.loginService.verifyPin({ pin: this.pin }).subscribe(
            (res) => {
                this.isLoading = false;
                this.pin = '';
                if (res.success) {
                    switch (this.modelType) {
                        case 'update':
                            this.openUpdateForm();
                            break;
                        case 'deactivate':
                            this.deactivateEmployee();
                            break;
                    }
                } else {
                    this.display = true;
                    this.eMessage = 'Invalid Password.';
                }
            },
            (err) => {
                this.isLoading = false;
                this.pin = '';
                this.display = true;
                this.eMessage = 'Invalid Password.';
            },
        );
    }

    isFieldInValid(field: string) {
        return FormUtils.isFieldInValid(this.employeeForm, field);
    }

    displayFieldCss(field: string) {
        return FormUtils.displayFieldCss(this.employeeForm, field);
    }

    submitForm() {
        //this.invalidForm = this.employeeForm.invalid;
        //debugger

        if (this.employeeForm.invalid) {
            FormUtils.validateAllFormFields(this.employeeForm);
            return false;
        }

        this.closeForm(false);
        this.isLoading = true;

        this.enterpriseService.addEnterpriseStaff(this.employeeForm).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    this.setCreateForm();
                    this.submit();
                    this.displaySuccess = true;
                    this.sMessage = MessageConstant.CREATE_ENTERPRISE_EMPLOYEE_SUCCESS;
                } else {
                    this.display = true;
                    this.eMessage = res.status.message;
                }
            },
            (error) => {
                this.isLoading = false;
                this.display = true;
                this.eMessage = MessageConstant.CREATE_ENTERPRISE_EMPLOYEE_FAILED;
            },
        );
    }

    updateEmployee() {
        this.invalidUpdateForm = this.employeeUpdateForm.invalid;

        if (this.employeeUpdateForm.invalid) {
            return false;
        }

        this.closeUpdateForm();
        this.isLoading = true;

        this.enterpriseService.updateEnterpriseStaff(this.employeeUpdateForm).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    this.submit();
                    this.displaySuccess = true;
                    this.sMessage = MessageConstant.UPDATE_ENTERPRISE_EMPLOYEE_SUCCESS;
                } else {
                    this.display = true;
                    this.eMessage = res.status.message;
                }
            },
            (error) => {
                this.isLoading = false;
                this.display = true;
                this.eMessage = MessageConstant.UPDATE_ENTERPRISE_EMPLOYEE_FAILED;
            },
        );
    }

    updateEmployeePin() {
        if (this.employeePinUpdateForm.invalid) {
            return false;
        }

        if (!this.validPin()) {
            this.eMessage = 'Pin and Confirm pin do not match.';
            this.display = true;
            return false;
        }

        this.closePinUpdateForm();
        this.isLoading = true;

        this.enterpriseService.updateEnterpriseStaffPin(this.employeePinUpdateForm).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    this.submit();
                    this.displaySuccess = true;
                    this.sMessage = 'Security Pin Updated Successfully';
                } else {
                    this.display = true;
                    this.eMessage = res.status.message;
                }
            },
            (error) => {
                this.isLoading = false;
                this.display = true;
                this.eMessage = 'Failed to Update Security Pin';
            },
        );
    }

    deactivateEmployee() {
        this.isLoading = true;

        this.enterpriseService
            .deactivateEnterpriseStaff(this.employee.enterpriseStaffId, { deactivate: true })
            .subscribe(
                (res) => {
                    this.isLoading = false;
                    if (res.success) {
                        this.submit();
                        this.displaySuccess = true;
                        this.sMessage = MessageConstant.DEACTIVATE_ENTERPRISE_EMPLOYEE_SUCCESS;
                    } else {
                        this.display = true;
                        this.eMessage = res.status.message;
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.display = true;
                    this.eMessage = MessageConstant.DEACTIVATE_ENTERPRISE_EMPLOYEE_FAILED;
                },
            );
    }

    update(event: { type: string; user: Employee }) {
        this.employee = event.user;
        this.modelType = event.type;

        switch (this.modelType) {
            case 'update':
                this.authMessage = MessageConstant.VIEW_ENTERPRISE_EMPLOYEE;
                break;
            case 'deactivate':
                this.authMessage = MessageConstant.DEACTIVATE_ENTERPRISE_EMPLOYEE;
                break;
        }
        this.openPinModel();
    }
}
