import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appFloatOnly]',
})
export class appFloatOnlyDirective {
    @Input() maxVal: number = Number.MAX_SAFE_INTEGER;

    private navigationKeys = [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Enter',
        'Home',
        'End',
        'ArrowLeft',
        'ArrowRight',
        'Clear',
        'Copy',
        'Paste',
    ];

    constructor(private el: ElementRef) { }

    @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent): any {
        const regexpNumber: RegExp = /\d/;
        const val: string = this.el.nativeElement.value;

        if (
            this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
            ((e.key === 'a' || e.code === 'KeyA') && e.ctrlKey === true) || // Allow: Ctrl+A
            ((e.key === 'c' || e.code === 'KeyC') && e.ctrlKey === true) || // Allow: Ctrl+C
            ((e.key === 'v' || e.code === 'KeyV') && e.ctrlKey === true) || // Allow: Ctrl+V
            ((e.key === 'x' || e.code === 'KeyX') && e.ctrlKey === true) || // Allow: Ctrl+X
            ((e.key === 'a' || e.code === 'KeyA') && e.metaKey === true) || // Allow: Cmd+A (Mac)
            ((e.key === 'c' || e.code === 'KeyC') && e.metaKey === true) || // Allow: Cmd+C (Mac)
            ((e.key === 'v' || e.code === 'KeyV') && e.metaKey === true) || // Allow: Cmd+V (Mac)
            ((e.key === 'x' || e.code === 'KeyX') && e.metaKey === true) // Allow: Cmd+X (Mac)
        ) {
            return;
        } else if (e.key === '.') {
            if (val.includes('.')) {
                e.preventDefault();
            }
        } else if (regexpNumber.test(e.key)) {
            if (Number(val + e.key) < this.maxVal) {
                const arr = (val + e.key).split('.');
                if (arr.length > 1 && arr[1].length > 2) {
                    e.preventDefault();
                } else {
                    return;
                }
            } else {
                e.preventDefault();
            }
        } else {
            e.preventDefault();
        }
    }
}
