export class MessageConstant {

    // server error
    public static SERVER_ERROR = 'Server Unavailable. Try again later.';
    public static INVALID_CREDENTIALS = 'Invalid email or password';
    public static RECOVER_PASSWORD_REQUEST = 'Password recovery request generated successfully.';

    // Form validation
    public static INCOMPLETE_FORM = 'Please fill all required fields.';
    public static INVALID_EMAIL = 'The email-id is invalid';
    public static INVALID_MOBILE = 'The mobile number is invalid';
    public static INVALID_IDNUMBER = 'The id-number is invalid';
    public static INVALID_SA_IDNUMBER = 'South African ID number is invalid';
    public static INVALID_IDNUMBER_DOB = 'Date of birth does not match South African ID number';
    public static INVALID_DOB = 'Date of birth is invalid';

    public static STAFF_CREATED = 'Staff user created successfully';
    public static AGENT_CREATED = 'Agent created successfully';
    public static ENTERPRISE_CREATED = 'Enterprise created successfully';
    public static CREATE_ENTERPRISE_EMPLOYEE_SUCCESS = 'Employee Details Created Successfully';
    public static CREATE_ENTERPRISE_EMPLOYEE_FAILED = 'Failed to Create Employee';
    public static UPDATE_ENTERPRISE_EMPLOYEE_SUCCESS = 'Employee Details Created Successfully';
    public static UPDATE_ENTERPRISE_EMPLOYEE_FAILED = 'Failed to Update Employee';
    public static VIEW_ENTERPRISE_EMPLOYEE = 'Enter Admin Password to View Employee';
    public static DEACTIVATE_ENTERPRISE_EMPLOYEE = 'Enter Admin Password to Deactivate Employee';
    public static DEACTIVATE_ENTERPRISE_EMPLOYEE_FAILED = 'Failed to Deactivate Employee';
    public static DEACTIVATE_ENTERPRISE_EMPLOYEE_SUCCESS = 'Employee Deactivated Successfully';
    public static RESEND_CREATE_PASSWORD_LINK_SUCCESS =
        'New link generated successfully. Please ask user to check their email.';
    public static BANK_TRANSFER_MIN_VALUE = 'Transfer amount should not be less than 300.';
    public static BANK_TRANSFER_REQUEST_SUCCESS = 'Bank Transfer request Sent Successfully';
    public static BANK_TRANSFER_REQUEST_FAILED = 'Bank Transfer Request Failed';
    public static BANK_TRANSFER_UPDATE_REQUEST_SUCCESS =
        'Bank Transfer Request Updated Successfully';
    public static BANK_TRANSFER_WITHOUT_NETCASH_SUCCESSFUL =
        'Transfer to Bank Transaction Processed';
    public static BANK_TRANSFER_UPDATE_REQUEST_FAILED = 'Bank Transfer Update Request Failed';
    public static PROFILE_UPDATE_REQUEST_FAILED = 'Failed to Update Profile';
    public static REFERRAL_CODE_UPDATE_REQUEST_FAILED = 'Failed to Update Referrral code';
    public static TRANSACTION_UPDATE_SUCCESS = 'Transaction successfully updated';
    public static ADDRESS_UPDATE_SUCCESS = 'Address successfully updated';

    // Bank
    public static BANK_UPDATE_SUCCESS = 'Bank updated Successfully';

    // Banner
    public static BANNER_ACTIVATED = 'Banner Activated';
    public static BANNER_DEACTIVATED = 'Banner Deactivated';
    public static BANNER_CREATED = 'New Banner Successfully created';
    public static BANNER_UPDATE_FAILED = 'Banner Update Failed:';

    //smart safe
    public static SMART_SAFE_CREATED = 'Smart Safe created successfully';
    public static SMART_SAFE_UPDATED = 'Smart Safe {} updated successfully';
    public static CIT_CREATE_SUCCESS = 'CIT created successfully';
    public static CIT_UPDATE_SUCCESS = '{} CIT updated successfully';

    public static USER_ADMIN_SETTINGS_UPDATE_SUCCESS =
        'Administrative settings updated successfully';

    // Tooltip messages
    public static SUPPLIER_DIRECT_ICON_MSG =
        "This field cannot be updated unless a user's Agent has a safe attached to it.";
}
