<div id="{{ modalId }}" class="modal modal-container fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content modal-content-custom">
            <div class="modal-header modal-header-custom">
                <h5 class="modal-title modal-title-custom">{{ modalTitle || 'Details' }}</h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    (click)="modalCancelEvent(modalId)">
                    <span aria-hidden="true">
                        <img class="close-image" src="assets/images/close-x-purple.png" alt="Close"
                    /></span>
                </button>
            </div>

            <ng-content></ng-content>

            <div class="modal-footer-custom">
                <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-8">
                        <button
                            type="submit"
                            class="secondary-button"
                            data-dismiss="modal"
                            (click)="modalCancelEvent(modalId)">
                            {{ buttonCancelText }}
                        </button>
                        <button
                            type="submit"
                            class="primary-button"
                            (click)="modalConfirmEvent(modalId)">
                            {{ buttonConfirmText }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
