<div class="sideview-modal-window">
    <div class="sideview-modal-header mb-3">
        Product Order Details
        <a (click)="closeSideViewModal()"
            ><img class="float-right" src="assets/images/close-x-purple.png"
        /></a>
    </div>

    <div class="sideview-modal-body">
        <hr />
        <section>
            <table>
                <tbody>
                    <tr class="form-table-row" *ngFor="let lineItem of productOrder.lineItems">
                        <td>
                            <div id="title-container" class="row align-items-start">
                                <img
                                    alt="Product Image"
                                    src="{{
                                        lineItem.image.src || 'assets/images/placeholder.png'
                                    }}" />
                                <div class="col-md-9 pl-0">
                                    <span>{{ lineItem.productTitle }}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span>
                                R{{ lineItem.price | number: amountFormat }} x
                                {{ lineItem.quantity }}
                            </span>
                        </td>
                        <td>
                            <span>R{{ lineItem.linePrice | number: amountFormat }}</span>
                        </td>
                        <hr />
                    </tr>
                </tbody>
            </table>
        </section>
        <hr />
        <section class="delivery-address">
            <div class="section-heading mb-3">Delivery Address</div>
            <div>
                <div class="row">
                    <div class="col-md-4">Address:</div>
                    <div class="col-md-8">
                        {{ productOrder?.deliveryAddress?.address1 }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">City:</div>
                    <div class="col-md-8">
                        {{ productOrder?.deliveryAddress?.city }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">province:</div>
                    <div class="col-md-8">
                        {{ productOrder?.deliveryAddress?.province }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">country:</div>
                    <div class="col-md-8">
                        {{ productOrder?.deliveryAddress?.country }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">Zip:</div>
                    <div class="col-md-8">
                        {{ productOrder?.deliveryAddress?.zip }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">Phone:</div>
                    <div class="col-md-8">
                        {{ productOrder?.deliveryAddress?.phone }}
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
