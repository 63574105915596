import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { TransactionStatus } from 'src/app/enums/transaction.status';
import { TransactionType } from 'src/app/enums/transaction.type';
import { Bank } from 'src/app/model/bank';
import { Pagination } from 'src/app/model/Pagination';
import { Transaction } from 'src/app/model/transaction';
import { TransactionLog } from 'src/app/model/transaction.log';
import { CommonService } from 'src/app/services/common.service';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { LoginService } from 'src/app/services/login.service';
import { StorageService } from 'src/app/services/storage.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { AppConstants } from 'src/app/settings/app-constants';
import { MessageConstant } from 'src/app/settings/message.constant';

@Component({
    selector: 'app-enterprise-transfer-to-bank',
    templateUrl: './enterprise-transfer-to-bank.component.html',
    styleUrls: ['./enterprise-transfer-to-bank.component.css'],
})
export class EnterpriseTransferToBankComponent implements OnInit {
    amountFormat = AppConstants.AMOUNT_FORMAT;
    dateFormat = AppConstants.DATE_FORMAT;
    eMessage = '';
    sMessage = '';
    isLoading = false;
    disabled = true;
    search = false;
    display = false;
    displaySuccess = false;
    displayView = 'none';
    displayCancel = 'none';
    progressWidth = 25;
    fullBalance = 0;
    availableBalance = 0;
    transactionList: Array<Transaction>;
    transaction: Transaction = new Transaction();
    pagination = new Pagination();
    transactionStatusEnum = TransactionStatus;
    form: FormGroup;
    id: string;
    checkBalance: any;
    showFullBalance = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private enterpriseService: EnterpriseService,
        private loginService: LoginService,
        private transactionService: TransactionService,
        private commonService: CommonService,
        private storageService: StorageService,
    ) {
        this.id = this.loginService.sessionUser.value.enterprise.enterpriseId;

        this.form = this.formBuilder.group({
            text: [''],
            transactionType: [TransactionType.transfer_to_bank],
            start: [''],
            length: [''],
            enterpriseId: [''],
        });

        this.getData();
    }

    ngOnInit() {
        this.form.get('text').valueChanges.subscribe((newVal) => {
            if (this.form.get('text').value !== '') {
                this.disabled = false;
            }
        });
    }

    getData() {
        this.search = false;
        this.isLoading = true;

        this.form.get('enterpriseId').setValue(this.id);
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);
        this.setProgressWidth(50);
        this.enterpriseService.getEmployeeTransactionHistory(this.form).subscribe(
            (res) => {
                this.transactionList = res.data;
                this.pagination.total = !res.total ? 0 : res.total;
                this.isLoading = false;
                this.search = true;
            },
            (error) => {
                this.isLoading = false;
            },
        );

        this.enterpriseService.getEnterpriseProfile(this.id).subscribe((res) => {
            this.storageService.setEnterpriseProfile(res.data);
            this.fullBalance = parseFloat(res.data.fullBalance);
            this.availableBalance = parseFloat(res.data.availableBalance);
        });
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    searchUsers(text: string) {
        this.pagination.text = text;
        this.getData();
    }

    clearText() {
        this.form.get('text').setValue('');
    }

    receiveFilter(data: { name: string; value: number }) { }

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    closeModel(event: any) {
        this.display = false;
        this.displaySuccess = false;
    }

    openViewModel() {
        this.displayView = 'block';
    }

    closeViewModel() {
        this.displayView = 'none';
    }

    openCancelModel() {
        this.displayCancel = 'block';
    }

    closeCancelModel() {
        this.displayCancel = 'none';
    }

    submit() {
        this.closeModel(true);
        let val = 0;

        if (this.form.get('text').value !== '') {
            val = parseFloat(this.form.get('text').value);
        }

        if (val < 300) {
            this.display = true;
            this.eMessage = MessageConstant.BANK_TRANSFER_MIN_VALUE;
            return false;
        }

        this.isLoading = true;

        const createForm = this.formBuilder.group({
            amount: [this.form.get('text').value],
            fromWalletId: [this.loginService.sessionUser.value.walletId],
            transactionType: [TransactionType.transfer_to_bank],
            enterpriseId: [this.id],
            toWalletId: 1,
            bankDetailId: this.storageService.profileEnterprise.value.bank.bankDetailId,
            transactionTypeCode: 'ttb',
        });

        this.clearText();

        this.commonService.checkUserBalance(createForm.value).subscribe(
            (res) => {
                this.isLoading = false;

                if (res.success) {
                    this.isLoading = true;
                    this.enterpriseService.createTransferToBank(createForm.value).subscribe(
                        (res) => {
                            this.isLoading = false;
                            this.getData();
                            this.showFullBalance = false;
                            if (res.success) {
                                this.displaySuccess = true;
                                this.sMessage = MessageConstant.BANK_TRANSFER_REQUEST_SUCCESS;
                            } else {
                                this.display = true;
                                this.eMessage = MessageConstant.BANK_TRANSFER_REQUEST_FAILED;
                            }
                        },
                        (error) => {
                            this.isLoading = false;
                            this.getData();
                        },
                    );
                } else {
                    if (res.hasEnoughBalance) {
                        this.display = true;
                        this.eMessage = MessageConstant.BANK_TRANSFER_REQUEST_FAILED;
                    } else {
                        this.showFullBalance = true;
                        this.display = true;
                        this.eMessage = res.status.message;
                        this.checkBalance = res;
                    }
                }
            },
            (error) => {
                this.isLoading = false;
                this.getData();
            },
        );
    }

    cancelRequest() {
        this.isLoading = true;
        this.closeCancelModel();

        const cancelForm = this.formBuilder.group({
            status: ['cancelled'],
            manualChangeReason: [''],
        });

        this.transactionService
            .updateTransferToBank(cancelForm.value, this.transaction.settlementRequestId)
            .subscribe(
                (res) => {
                    this.isLoading = false;
                    this.getData();

                    if (res.success) {
                        this.displaySuccess = true;
                        this.sMessage = MessageConstant.BANK_TRANSFER_UPDATE_REQUEST_SUCCESS;
                    } else {
                        this.display = true;
                        this.eMessage = MessageConstant.BANK_TRANSFER_UPDATE_REQUEST_FAILED;
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.getData();
                },
            );
    }

    action(event: { type: string; value: Transaction }) {
        this.transaction = event.value;

        if (!this.transaction.bankDetails) {
            this.transaction.bankDetails = new Bank();
        }

        if (!this.transaction.transactionLog) {
            this.transaction.transactionLog = new Array<TransactionLog>();
        }

        switch (event.type) {
            case 'view':
                this.openViewModel();
                break;
            case 'cancel':
                this.openCancelModel();
                break;
        }
    }

    setBalance() {
        if (this.checkBalance) {
            this.form.get('text').setValue(this.checkBalance.maxTxnAmountAllowed);
        }
    }
}
