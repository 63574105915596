import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { AlertService } from 'src/app/services/alert.service';
import { CompanyService } from 'src/app/services/company.service';
import { CountryCode } from '../enums/country.code';
import { Role } from '../enums/role';
import { LoginForm } from '../model/login.form';
import { SessionUser } from '../model/session.user';
import { AppSettings } from '../settings/app-settings';
import { StorageConstant } from '../settings/storage-constants';
import { URI } from '../settings/uri-constants';

@Injectable({ providedIn: 'root' })
export class LoginService {
    sessionUser = new BehaviorSubject<SessionUser>(null);
    skipUrlList = [
        URI.LOGIN,
        URI.FORGOT_PASSWORD,
        URI.PRIVACY_POLICY,
        URI.TAC_AGENT,
        URI.TAC_MERCHANT,
        URI.TAC_PERSONAL,
        URI.CREATE_PASSWORD,
        URI.LOGIN_TFA,
    ];
    alertOptions = {
        autoClose: true,
        keepAfterRouteChange: true,
    };

    constructor(
        private http: HttpClient,
        private companyService: CompanyService,
        private router: Router,
        private alertService: AlertService,
        private toasterService: ToastrService,
    ) { }

    handleAuthentication(admin: SessionUser) {
        const user = new SessionUser();
        user.setFields(
            admin['X-Auth-Key'],
            admin.name,
            admin.email,
            admin.userId,
            admin.userRoleId,
            admin.walletId,
            admin.role,
            admin.enterprise,
        );

        this.sessionUser.next(user);
        localStorage.setItem(StorageConstant.AUTH_USER, JSON.stringify(user));
    }

    signIn(postData: LoginForm) {
        const headers = new HttpHeaders({
            Authorization: 'Basic ' + btoa(postData.email + ':' + postData.password),
        });

        const body = {
            companyId: 1,
            countryCode: CountryCode.south_africa,
            deviceId:
                Math.random().toString(36).substring(0, 8) +
                Math.random().toString(36).substring(0, 8),
        };

        return this.http
            .post<SessionUser>(AppSettings.API_ENDPOINT + AppSettings.LOGIN, body, { headers })
            .pipe(
                tap((response) => {
                    if (response.success) {
                        this.handleAuthentication(response);
                    }
                }),
            );
    }

    validateCredentials(postData: any) {
        return this.http.post<any>(
            AppSettings.API_ENDPOINT + AppSettings.VALIDATE_CREDENTIALS,
            postData,
        );
    }

    verifyPin(data: any) {
        return this.http.post<any>(AppSettings.API_ENDPOINT + AppSettings.VERIFY_PIN, data);
    }

    forgotPassword(email: string) {
        const path = AppSettings.FORGOT_PASSWORD + email;
        return this.http.post<any>(AppSettings.API_ENDPOINT + path, {});
    }

    createPassword(body: any, id: string) {
        return this.http.post<any>(
            AppSettings.API_ENDPOINT + AppSettings.CREATE_PASSWORD + '/' + id,
            body,
        );
    }

    resendCreatePasswordLink(code: string) {
        const path = AppSettings.RESEND_CREATE_PASSWORD_LINK.replace('@1@', code);
        return this.http.get<any>(AppSettings.API_ENDPOINT + path);
    }

    changePassword(body: any, id: string) {
        const headers = new HttpHeaders({ 'X-Auth-Key': id });
        return this.http.post<any>(AppSettings.API_ENDPOINT + AppSettings.CHANGE_PASSWORD, body, {
            headers,
        });
    }

    logout() {
        this.router.navigate([URI.HOME]);
        // this.toasterService.success(
        //     `Goodbye, ${this.sessionUser.value.name}`,
        //     'Logged Out Successfully...',
        // );
        this.sessionUser.next(null);
        localStorage.clear();
    }

    autoLogin() {
        const userData: SessionUser = JSON.parse(localStorage.getItem(StorageConstant.AUTH_USER));

        if (!userData) {
            return false;
        }

        if (userData['X-Auth-Key']) {
            this.sessionUser.next(userData);
        }

        return true;
    }

    isLoggedIn(): boolean {
        const userData: SessionUser = JSON.parse(localStorage.getItem(StorageConstant.AUTH_USER));
        return !!userData;
    }

    isAdmin() {
        return this.checkRole(Role.staff);
    }

    isEnterprise() {
        return this.checkRole(Role.enterprise) || this.checkRole(Role.enterprise_contact);
    }

    checkRole(role: string) {
        if (this.sessionUser.value.role == role) {
            return true;
        }
        return false;
    }

    skipUrl(url: string) {
        for (const val of this.skipUrlList) {
            if (url === val) {
                return true;
            }
        }
        return true;
    }

    routeByRoleOnLogin(role: string) {
        switch (role) {
            case Role.staff:
                this.fetchCompanyDetails(URI.getRouteNavPath('dashboard'));
                break;
            case Role.enterprise_contact:
                this.fetchCompanyDetails(URI.getRouteNavPath('enterpriseProfile'));
                break;
            default:
                this.logout();
        }
    }

    private fetchCompanyDetails(routeUrl) {
        this.toasterService.success(
            `Welcome, ${this.sessionUser.value.name}`,
            'Logged In Successfully...',
        );
        this.companyService.retrieveCompanies().subscribe(
            (response) => {
                if (response.success) {
                    this.companyService.setCompanies(response.responseBody.data);
                    this.router.navigate([routeUrl]);
                } else {
                    console.log(response.status.message);
                }
            },
            (error) => {
                console.log(error);
            },
        );
    }
}
