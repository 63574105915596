import { DatePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ICashbacksRedeemed } from 'src/app/model/cashbacks.redeemed';
import { ListView } from 'src/app/model/list.view';
import { CashbackReportService } from 'src/app/services/cashback-report.service';
import { LoginService } from 'src/app/services/login.service';
import { AppConstants } from 'src/app/settings/app-constants';

@Component({
    selector: 'app-enterprise-cashbacks-redeemed',
    templateUrl: './enterprise-cashbacks-redeemed.component.html',
    styleUrl: './enterprise-cashbacks-redeemed.component.css'
})
export class EnterpriseCashbacksRedeemedComponent extends ListView implements OnInit {

    cashbackReportService = inject(CashbackReportService);
    datePipe = inject(DatePipe);
    loginService = inject(LoginService);
    dataDownload: ICashbacksRedeemed[] = [];
    cashbacksRedeemed: ICashbacksRedeemed[] = [];
    cashbacksRedeemedToday = 0;
    balance = 0;
    amountFormat = AppConstants.AMOUNT_FORMAT;
    enterpriseId = '';
    excelDataList = new Array<any>();
    downloadLinkId = 'cashback-redeemed-link-id'
    download = false;
    data: any;

    CASHBACK_REDEEMED_DEFAULT_DATE = '2024-11-20';

    // Set the minimum to the date Cashback Report was introduced to the system
    // and set maaximum date to today's date.
    readonly minDate = new Date(this.CASHBACK_REDEEMED_DEFAULT_DATE);
    readonly maxDate = new Date();

    constructor() {
        super()
        this.enterpriseId = this.loginService.sessionUser.value.enterprise.enterpriseId;
    }

    ngOnInit() {
        this.addSearchControls();
        //this.searchForm.get('text').disabled;
        this.getData();
    }

    getData() {
        this.isLoading = true;
        this.setPaginationParams();
        this.setDefaultDateRange();
        this.setProgressWidth(50);

        this.fetchCashbacksRedeemed();
    }

    getDownloadData() {
        this.download = true;
        let totalAmount = 0;
        this.excelDataList = [];
        const { total } = this.pagination;

        // Update form values for download
        this.searchForm.patchValue({ length: total, start: 0 });

        // Fetch cashback reports and process response
        this.cashbackReportService.getCashbackRedeemed(this.enterpriseId, this.getSearchParams()).subscribe({
            next: (res) => {
                this.data = res.responseBody.data;
                this.dataDownload = this.data?.cashbacksRedeemed || [];

                // Process each cashback redeemed for Excel export
                this.dataDownload.forEach(cashbackRedeemed => {
                    totalAmount += cashbackRedeemed.shopifyOrder?.totalAmount;
                    this.excelDataList.push(this.getExcelRowData(cashbackRedeemed));
                });

                // Add total row to Excel data
                this.excelDataList.push(this.getEmptyExcelRow());

                this.handleClick();
            },
            error: () => {
                this.isLoading = false;
            }
        });
    }

    setDefaultDateRange() {
        let fromDate = this.searchForm.get('fromDate').value;
        let toDate = this.searchForm.get('toDate').value;

        // Exit early if both dates are empty
        if (!fromDate && !toDate) {
            return;
        }

        if (fromDate) {
            const formattedFromDate = this.datePipe.transform(fromDate, 'yyyy-MM-dd');
            this.searchForm.get('fromDate').setValue(formattedFromDate);
        }

        if (toDate) {
            const formattedToDate = this.datePipe.transform(toDate, 'yyyy-MM-dd');
            this.searchForm.get('toDate').setValue(formattedToDate);
        }
    }

    addSearchControls() {
        // Initialize the newControlsToAdd object with default values
        const defaultControls = {
            fromDate: '',
            toDate: ''
        };

        // Assign default controls to newControlsToAdd
        this.newControlsToAdd = { ...defaultControls };

        // Call this inherited method to add the new controls
        this.addNewControls();
    }

    /**** 1. Public Methods for UI Interactions ****/

    submit() {
        this.getData();
    }

    clearDates() {
        this.searchForm.get('fromDate').setValue('');
        this.searchForm.get('toDate').setValue('');
        this.getData();
    }

    /**** 2. Private Helper Methods for Data Handling and API Calls ****/

    private setPaginationParams() {
        this.searchForm.patchValue({
            start: this.pagination.start,
            length: this.pagination.length
        });
    }

    private fetchCashbacksRedeemed() {
        this.download = false;
        this.cashbackReportService.getCashbackRedeemed(this.enterpriseId, this.searchForm.value).subscribe({
            next: (res) => this.handleSuccess(res),
            error: () => this.handleError(),
            complete: () => this.handleCompletion()
        });
    }

    private handleSuccess(res: any) {
        const { data, total } = res.responseBody;

        if (!data) return; // Early return if no data is available

        const { cashbacksRedeemed, balance, cashbacksRedeemedToday } = data;

        this.data = data;
        this.balance = balance;
        this.cashbacksRedeemed = cashbacksRedeemed;
        this.cashbacksRedeemedToday = cashbacksRedeemedToday;
        this.pagination.total = total;
    }

    private handleError() {
        this.isLoading = false;
    }

    private handleCompletion() {
        this.isLoading = false;
        this.showSearchResultRow = !this.isLoading && this.cashbacksRedeemed?.length > 0;
    }

    // 3. Utility Methods for Excel Formatting
    private getExcelRowData(cr: ICashbacksRedeemed) {
        return {
            'CUSTOMER': cr.customer,
            'ORDER NR': cr?.shopifyOrder?.orderNumber,
            'SALE INCLUSIVE': cr?.shopifyOrder?.totalAmount,
            'DATE': this.datePipe.transform(cr.createdAt, 'd/MM/yyyy'),
            'CASHBACK REDEEMED': cr.cashbackAmountRedeemed
        };
    }

    private getEmptyExcelRow() {
        return {
            'CUSTOMER': '',
            'ORDER NR': '',
            'SALE INCLUSIVE': '',
            'DATE': '',
            'CASHBACK REDEEMED': ''
        };
    }

    // 4. Method for Handling Click
    handleClick() {
        document.getElementById(this.downloadLinkId)?.click();
    }

    // 5. Utility Methods for Search Parameters
    getSearchParams() {
        const params = { ...this.searchForm.value };

        if (params.fromDate) {
            params.fromDate = this.datePipe.transform(params.fromDate, 'yyyy-MM-dd');
        } else {
            params.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        }

        if (params.toDate) {
            params.toDate = this.datePipe.transform(params.toDate, 'yyyy-MM-dd');
        } else {
            params.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        }

        return params;
    }
}
