import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { AppConstants } from 'src/app/settings/app-constants';

@Component({
    selector: 'app-product-order-details',
    templateUrl: './product-order-details.component.html',
    styleUrls: ['./product-order-details.component.css'],
})
export class ProductOrderDetailsComponent {
    @Input() productOrder: any;
    @Output() event = new EventEmitter<boolean>();
    amountFormat = AppConstants.AMOUNT_FORMAT;

    constructor(private modalService: ModalService) { }

    closeSideViewModal() {
        this.event.emit(false);
    }

    // openSideViewModal(id: string) {
    //     this.modalService.open(id);
    // }
}
