@if (edit) {
    <section class="add-section">
        <div class="row">
            <div class="col-md-8 section-heading">
                <ng-container>Address</ng-container>
            </div>

            <div class="col-md-4 section-heading">
                <app-edit-pencil (edit)="changeView($event)"></app-edit-pencil>
            </div>
        </div>
        <form [formGroup]="addressForm" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-md-6 m-0">
                    <label for="address.addressLineOne" class="form-label">
                        ADDRESS LINE ONE*
                    </label>
                    <div>
                        <input
                            class="form-input"
                            type="text"
                            placeholder="e.g. abc road"
                            name="addressLineOne"
                            formControlName="addressLineOne"
                            [ngClass]="formUtils.displayFieldCss(addressForm, 'addressLineOne')" />
                    </div>
                    <app-field-error-display
                        [displayError]="formUtils.isFieldInValid(addressForm, 'addressLineOne')"
                        errorMsg="Address line one is required">
                    </app-field-error-display>
                </div>

                <div class="col-md-6 m-0">
                    <label for="address.addressLineTwo" class="form-label">
                        ADDRESS LINE TWO
                    </label>
                    <div>
                        <input
                            class="form-input"
                            type="text"
                            placeholder="e.g. abc road"
                            name="addressLineTwo"
                            formControlName="addressLineTwo" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <label class="form-label">BUILDING DETAILS</label>
                    <div>
                        <input
                            class="form-input"
                            type="text"
                            placeholder="e.g. abc road"
                            name="buildingDetails"
                            formControlName="buildingDetails" />
                    </div>
                </div>
                <div class="col-md-6">
                    <label for="address.province" class="form-label">PROVINCE*</label>
                    <div>
                        <ng-select
                            [items]="provinceList"
                            bindLabel="province"
                            [multiple]="false"
                            [clearable]="false"
                            placeholder="Select Province"
                            formControlName="province"
                            bindValue="id"
                            [ngClass]="formUtils.displayFieldCss(addressForm, 'province')">
                        </ng-select>
                    </div>
                    <app-field-error-display
                        [displayError]="formUtils.isFieldInValid(addressForm, 'province')"
                        errorMsg="Select a province">
                    </app-field-error-display>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label for="address.cityId" class="form-label">CITY*</label>
                    <ng-select
                        [items]="cityList"
                        bindLabel="city"
                        placeholder="Select City"
                        [multiple]="false"
                        [clearable]="false"
                        formControlName="cityId"
                        bindValue="id"
                        [ngClass]="formUtils.displayFieldCss(addressForm, 'cityId')">
                    </ng-select>
                    <app-field-error-display
                        [displayError]="formUtils.isFieldInValid(addressForm, 'cityId')"
                        errorMsg="Select a city">
                    </app-field-error-display>
                </div>

                <div class="col-md-6">
                    <label for="address.suburb" class="form-label">SUBURB*</label>
                    <div>
                        <input
                            class="form-input"
                            type="text"
                            placeholder="suburb"
                            name="suburb"
                            formControlName="suburb"
                            [ngClass]="formUtils.displayFieldCss(addressForm, 'suburb')" />
                    </div>
                    <app-field-error-display
                        [displayError]="formUtils.isFieldInValid(addressForm, 'suburb')"
                        errorMsg="Suburb is required">
                    </app-field-error-display>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label for="address.postalCode" class="form-label">POSTAL CODE*</label>
                    <div>
                        <input
                            class="form-input"
                            type="text"
                            placeholder="e.g. 7708"
                            name="postalCode"
                            formControlName="postalCode"
                            [ngClass]="formUtils.displayFieldCss(addressForm, 'postalCode')" />
                    </div>
                    <app-field-error-display
                        [displayError]="formUtils.isFieldInValid(addressForm, 'postalCode')"
                        errorMsg="Postal Code is required">
                    </app-field-error-display>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <button class="custombtn custombtn-align-right" (click)="submit()">SAVE</button>
                    <a class="user-files-deny" (click)="changeView($event)"><u>CANCEL</u></a>
                </div>
            </div>
        </form>
    </section>
}
@if (!edit) {
    <section class="add-section">
        <div class="row">
            <div class="col-md-8 section-heading">
                <ng-container>Address</ng-container>
            </div>

            <div class="col-md-4 section-heading">
                <app-edit-pencil (edit)="changeView($event)"></app-edit-pencil>
            </div>
        </div>
        <div class="details-view">
            <div class="row">
                <div class="col-md-3">
                    <label for="addressLineOne" class="form-label">ADDRESS LINE ONE</label>
                    <div class="user-details-text">
                        {{ address?.addressLineOne || '-' }}
                    </div>
                </div>
                <div class="col-md-3">
                    <label for="addressLineTwo" class="form-label">ADDRESS LINE TWO</label>
                    <div class="user-details-text">
                        {{ address?.addressLineTwo || '-' }}
                    </div>
                </div>

                <div class="col-md-3">
                    <label for="buildingDetails" class="form-label">BUILDING DETAILS</label>
                    <div class="user-details-text">
                        {{ address?.buildingDetails || '-' }}
                    </div>
                </div>
                <div class="col-md-3">
                    <label for="address.suburb" class="form-label">SUBURB</label>
                    <div class="user-details-text">
                        {{ address?.suburb || '-' }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <label for="province" class="form-label">PROVINCE</label>
                    <div class="user-details-text">
                        {{ address?.provinceName || '-' }}
                    </div>
                </div>
                <div class="col-md-3">
                    <label for="cityId" class="form-label">CITY</label>
                    <div class="user-details-text">
                        {{ address?.cityName || '-' }}
                    </div>
                </div>
                <div class="col-md-3">
                    <label for="address.postalCode" class="form-label">POSTAL CODE</label>
                    <div class="user-details-text">
                        {{ address?.postalCode || '-' }}
                    </div>
                </div>
            </div>
        </div>
    </section>
}
