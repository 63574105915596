<div class="wrapper">
    <app-navigation></app-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>

        <div>
            <section class="add-section width-50">
                <div>
                    <div class="row">
                        <div class="col-md-6 section-heading">Cashback Wallet Balance</div>
                    </div>
                    <div class="label-value">
                        <a
                            href="{{ 'https://wavesexplorer.com/addresses/' + this.publicKey }}"
                            target="_blank">
                            Blockchain Transactions
                        </a>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <small>AVAILABLE:</small>
                        </div>
                        <div class="col-md-6">
                            <small>TOTAL CASHBACKS GIVEN TODAY:</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <span>{{ balance || '0.00' | number: amountFormat }}</span>
                        </div>
                        <div class="col-md-6">
                            <span>{{ cashbackReportTotal || '0.00' | number: amountFormat }}</span>
                        </div>
                    </div>
                </div>
            </section>
            <section class="add-section">
                <form [formGroup]="searchForm">
                    <div class="row">
                        <div class="col-md-12 section-heading">Search</div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <div class="input-icons">
                                <i class="fa fa-search icon" aria-hidden="true"></i>
                                <input
                                    name="text"
                                    class="form-input"
                                    type="text"
                                    placeholder="Enter Product, Wholesaler, or Supplier Name"
                                    formControlName="text" />
                                @if (searchForm.get('text').value.length > 0) {
                                    <a (click)="clearText()">
                                        <img src="assets/images/close-x-purple.png" />
                                    </a>
                                }
                            </div>
                        </div>
                        <div class="col-md-7">
                            <button class="search-button" (click)="submit()">Search</button>
                            <a class="advanced-search" (click)="toggleAdvancedSearch()">
                                <img src="assets/images/dropdown.png" />
                                <u>ADVANCED SEARCH</u>
                            </a>
                        </div>
                    </div>
                    @if (showAdvancedSearch) {
                        <hr />
                        <div class="search-form">
                            <div class="row">
                                <div class="col-md-4">
                                    <label for="supplierId" class="form-label">SUPPLIER</label>
                                    <div>
                                        <ng-select
                                            labelForId="supplierId"
                                            [items]="supplierList"
                                            [loading]="isLoading"
                                            bindLabel="name"
                                            formControlName="supplierId"
                                            bindValue="supplierId"
                                            name="supplierId"
                                            [searchable]="false"
                                            [clearable]="true"
                                            placeholder="Select Supplier"
                                            (change)="onChange($event, 'supplierId')"
                                            (clear)="onClear('supplierId')"
                                            (remove)="onRemove($event, 'supplierId')"
                                            [multiple]="false">
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label for="wholesalerId" class="form-label">WHOLESALER</label>
                                    <div>
                                        <ng-select
                                            labelForId="wholesalerId"
                                            [items]="wholesalerList"
                                            [loading]="isLoading"
                                            bindLabel="name"
                                            placeholder="Select Wholesaler"
                                            formControlName="wholesalerId"
                                            bindValue="wholesalerId"
                                            name="wholesalerId"
                                            [searchable]="true"
                                            [clearable]="true"
                                            (change)="onChange($event, 'wholesalerId')"
                                            (clear)="onClear('wholesalerId')"
                                            (remove)="onRemove($event, 'wholesalerId')"
                                            [multiple]="false">
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label for="variantId" class="form-label">PRODUCT</label>
                                    <div>
                                        <ng-select
                                            labelForId="variantId"
                                            [items]="productList"
                                            [loading]="isLoading"
                                            bindLabel="name"
                                            placeholder="Select Product"
                                            formControlName="variantIds"
                                            bindValue="variantId"
                                            searchable="false"
                                            name="variantId"
                                            (change)="onChange($event, 'variantId')"
                                            (clear)="onClear('variantId')"
                                            (remove)="onRemove($event, 'variantId')"
                                            [multiple]="true">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <label for="fromDateId" class="form-label date-label">
                                        START DATE
                                    </label>
                                    <div class="datepicker">
                                        <input
                                            id="fromDateId"
                                            matInput
                                            class="datepicker-input"
                                            [min]="minDate"
                                            [max]="maxDate"
                                            [matDatepicker]="picker"
                                            name="fromDate"
                                            formControlName="fromDate"
                                            readonly />
                                        <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label for="toDateId" class="form-label date-label">
                                        END DATE
                                    </label>
                                    <div class="datepicker">
                                        <input
                                            id="toDateId"
                                            matInput
                                            class="datepicker-input"
                                            [min]="minDate"
                                            [max]="maxDate"
                                            [matDatepicker]="picker1"
                                            name="toDate"
                                            formControlName="toDate"
                                            readonly />
                                        <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label class="form-label"></label>
                                    <div>
                                        <a
                                            class="p-3 text-danger line-height-3"
                                            (click)="clearDates()"
                                            ><u>Clear Dates</u></a
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="searched-items">
                            <div *ngFor="let key of searchItems | keyvalue">
                                <div *ngIf="key.value?.length > 0">
                                    <div *ngFor="let item of key.value" class="search-item-value">
                                        {{ item | humanize }}
                                        <!-- <a (click)="removeSearchItem(key.key, item)">
                                            <img src="assets/images/close-x-white.png" />
                                        </a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </form>
            </section>
        </div>

        @if (showSearchResultRow) {
            <section>
                <div class="container-fluid tab-pane">
                    <br />
                    <div class="row">
                        <div class="col-md-10 section-heading">
                            Search Results: {{ cashbackReports.length }} of
                            {{ pagination.total }}
                        </div>
                        <div class="col-md-2">
                            <button
                                class="custombtn custombtn-align-right"
                                (click)="getCashbackReportDownloadData()">
                                <i class="fa fa-arrow-up" aria-hidden="true"></i>Export Cashbacks
                            </button>
                            <a
                                appExportExcel
                                hidden
                                [data]="excelDataList"
                                fileName="CashbackReports"
                                id="{{ downloadLinkId }}"
                                class="download-transaction">
                                <u>DOWNLOAD REPORTS</u>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        }

        <hr />

        @if (isLoading) {
            <div class="progress-container">
                <div class="progress-container-text">
                    <div>{{ loaderText }}</div>
                </div>
                <div>
                    <div class="custom-progress-bar">
                        <span [style.width.%]="progressWidth"></span>
                    </div>
                </div>
            </div>
        }

        <div class="container-fluid tab-pane custom-table custom-last-row">
            <br />
            <table>
                <thead>
                    <tr class="form-table-row">
                        <th>
                            <app-filter-arrow
                                filter="customer"
                                title="CUSTOMER"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="wholesaler"
                                title="WHOLESALER"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="supplier"
                                title="SUPPLIER"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="product"
                                title="PRODUCT"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="quantity"
                                title="QTY."
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="amount"
                                title="AMOUNT"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="date"
                                title="DATE"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="shopifyOrderNo"
                                title="INVOICE NO."
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container>
                        @for (cr of cashbackReports; track cr.orderId + cr.variantId) {
                            <tr class="form-table-row">
                                <td>
                                    <strong>{{ cr.toUser }}</strong>
                                </td>
                                <td>{{ cr.wholesaler }}</td>
                                <td>{{ cr.supplier }}</td>
                                <td>
                                    <strong>{{ cr.title }}</strong>
                                </td>
                                <td>{{ cr.quantity }}</td>
                                <td>{{ cr.amount | currency: 'ZAR' : 'symbol-narrow' }}</td>
                                <td>{{ cr.createdAt | date: 'd/M/yyyy - h:mm a' }}</td>
                                <td>{{ cr.orderNumber }}</td>
                            </tr>
                        } @empty {
                            <tr>
                                <div class="data-empty">
                                    <div class="col-md-12">No data to display</div>
                                </div>
                            </tr>
                        }
                    </ng-container>
                </tbody>
                <tfoot *ngIf="pagination.showPagination()">
                    <tr>
                        <td>
                            Rows per page:
                            <span>
                                <b>{{ pagination.length }}</b>
                            </span>
                            <app-page-length-dropdown (emitLength)="changeLength($event)">
                            </app-page-length-dropdown>
                        </td>
                        <td>
                            <app-page-navigation
                                pageNumber="{{ pagination.getPageNo() }}"
                                totalPages="{{ pagination.getTotalPages() }}"
                                (changePage)="changePage($event)">
                            </app-page-navigation>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <div class="control-sidebar-bg"></div>
    </div>
</div>
