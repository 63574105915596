import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UserAddress } from 'src/app/model/user.address';
import { CommonService } from 'src/app/services/common.service';
import { FormUtils } from 'src/app/utils/form.utils';

@Component({
    selector: 'app-user-address',
    templateUrl: './user-address.component.html',
    styleUrl: './user-address.component.css'
})
export class UserAddressComponent implements OnInit, OnChanges {

    // Dropdown List
    provinceList: string[] = [];
    cityList: string[] = [];
    province: string = '';
    city: string = '';

    formUtils = FormUtils;
    edit = false;
    @Input() address: UserAddress = new UserAddress();
    @Input() type: string = 'office';
    @Output() update = new EventEmitter<FormGroup>();

    addressForm = this.formBuilder.group({
        type: [this.type, Validators.required],
        addressLineOne: ['', Validators.required],
        addressLineTwo: [''],
        buildingDetails: [''],
        province: ['', Validators.required],
        provinceName: [''],
        cityId: ['', Validators.required],
        cityName: [''],
        suburb: ['', Validators.required],
        postalCode: ['', Validators.required],
    });

    constructor(
        private formBuilder: FormBuilder,
        private commonService: CommonService) {
        this.address = new UserAddress();
    }

    ngOnInit(): void {
        this.commonService.getProvinces({ allowedForShop: true }).subscribe((res) => {
            this.provinceList = res;
        });

        this.addressForm.get('province').valueChanges.subscribe((provinceSelectionId) => {
            this.addressForm.get('cityId').setValue('');
            if (provinceSelectionId) {
                this.commonService.getCities(provinceSelectionId).subscribe((res) => {
                    this.cityList = res.responseBody.data || [];
                });
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.address && changes.address.currentValue !== changes.address.previousValue) {
            this.setForm(changes.address.currentValue);
        }
    }

    changeView(event: any) {
        this.edit = !this.edit;
        this.setForm(this.address);
    }

    setForm(address: UserAddress) {
        this.addressForm.get('type').setValue(address.type || this.type);
        this.addressForm.get('addressLineOne').setValue(address.addressLineOne);
        this.addressForm.get('addressLineTwo').setValue(address.addressLineTwo);
        this.addressForm.get('buildingDetails').setValue(address.buildingDetails);
        this.addressForm.get('province').setValue(address.province);
        this.addressForm.get('provinceName').setValue(address.provinceName);
        this.addressForm.get('cityId').setValue(address.cityId);
        this.addressForm.get('cityName').setValue(address.cityName);
        this.addressForm.get('suburb').setValue(address.suburb);
        this.addressForm.get('postalCode').setValue(address.postalCode);
    }

    submit() {
        if (this.addressForm.invalid) {
            FormUtils.validateAllFormFields(this.addressForm);
        } else {
            this.edit = false;
            this.update.emit(this.addressForm);
        }
    }
}
