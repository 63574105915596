import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FeesComponent } from './admin/admin-settings/fees/fees.component';
import { PrivacyPolicyComponent } from './admin/admin-settings/privacy-policy/privacy-policy.component';
import { ActiveApplicationsComponent } from './admin/agent-applications/active-applications/active-applications.component';
import { AgentFilesComponent } from './admin/agent-applications/agent-files/agent-files.component';
import { DeniedApplicationsComponent } from './admin/agent-applications/denied-applications/denied-applications.component';
import { BannersComponent } from './admin/banner/index/banners.component';
import { NewBannerComponent } from './admin/banner/new-banner/new-banner.component';
import { CashbackReportComponent } from './admin/cashback-report/cashback-report.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { EftCashInDataComponent } from './admin/eft-cash-in/eft-cash-in-data/eft-cash-in-data.component';
import { EftCashInComponent } from './admin/eft-cash-in/eft-cash-in/eft-cash-in.component';
import { GrvComponent } from './admin/grv/grv.component';
import { AgentComponent } from './admin/manage-users/add-user/agent/agent.component';
import { EnterpriseUserComponent } from './admin/manage-users/add-user/enterprise-user/enterprise-user.component';
import { StaffUserComponent } from './admin/manage-users/add-user/staff-user/staff-user.component';
import { ChangeMobileComponent } from './admin/manage-users/change-mobile/change-mobile.component';
import { ManageComponent } from './admin/manage-users/manage/manage.component';
import { SearchComponent } from './admin/manage-users/search/search.component';
import { VerifyOtpComponent } from './admin/manage-users/verify-otp/verify-otp.component';
import { VerifyUsersComponent } from './admin/manage-users/verify-users/verify-users.component';
import { TransactionsComponent } from './admin/manage-users/view/transactions/transactions.component';
import { UserAgentProfileComponent } from './admin/manage-users/view/user-agent-profile/user-agent-profile.component';
import { UserMerchantProfileComponent } from './admin/manage-users/view/user-merchant-profile/user-merchant-profile.component';
import { UserPersonalProfileComponent } from './admin/manage-users/view/user-personal-profile/user-personal-profile.component';
import { UserProfileFilesComponent } from './admin/manage-users/view/user-profile-files/user-profile-files.component';
import { UserProfileSettingsComponent } from './admin/manage-users/view/user-profile-settings/user-profile-settings.component';
import { WalletSummaryComponent } from './admin/manage-users/view/wallet-summary/wallet-summary.component';
import { ProductOrdersComponent } from './admin/product-orders/product-orders.component';
import { CitAddCompanyComponent } from './admin/smart-safes/cash-in-transit/cit-add-company/cit-add-company.component';
import { CitCompaniesComponent } from './admin/smart-safes/cash-in-transit/cit-companies/cit-companies.component';
import { SafeEventsComponent } from './admin/smart-safes/safe-events/safe-events.component';
import { SafeSummaryComponent } from './admin/smart-safes/safe-summary/index/safe-summary.component';
import { SafeTransactionDetailsComponent } from './admin/smart-safes/safe-summary/safe-transaction-details/safe-transaction-details.component';
import { SafeUserDetailsComponent } from './admin/smart-safes/safe-summary/safe-user-details/safe-user-details.component';
import { SafesConfigurationComponent } from './admin/smart-safes/safes-configuration/index/safes-configuration.component';
import { AllTransactionsComponent } from './admin/transactions/all-transactions/all-transactions.component';
import { TransferToBankLogComponent } from './admin/transfer-to-bank/transfer-to-bank-log/transfer-to-bank-log.component';
import { TransferToBankManualExportComponent } from './admin/transfer-to-bank/transfer-to-bank-manual-export/transfer-to-bank-manual-export.component';
import { TransferToBankPendingComponent } from './admin/transfer-to-bank/transfer-to-bank-pending/transfer-to-bank-pending.component';
import { TransferToBankProcessedComponent } from './admin/transfer-to-bank/transfer-to-bank-processed/transfer-to-bank-processed.component';
import { EnterpriseCashbackSettingsComponent } from './enterprise/enterprise-cashback-settings/enterprise-cashback-settings.component';
import { EnterpriseDashboardComponent } from './enterprise/enterprise-dashboard/enterprise-dashboard.component';
import { EnterpriseEmployeesComponent } from './enterprise/enterprise-employees/enterprise-employees.component';
import { EnterpriseProductOrdersComponent } from './enterprise/enterprise-product-orders/enterprise-product-orders.component';
import { EnterpriseProfileComponent } from './enterprise/enterprise-profile/enterprise-profile.component';
import { EnterpriseSettingsComponent } from './enterprise/enterprise-settings/enterprise-settings.component';
import { EnterpriseTransactionsComponent } from './enterprise/enterprise-transactions/enterprise-transactions.component';
import { EnterpriseTransferToBankComponent } from './enterprise/enterprise-transfer-to-bank/enterprise-transfer-to-bank.component';
import { EnterpriseWalletSummaryComponent } from './enterprise/enterprise-wallet-summary/enterprise-wallet-summary.component';
import { CreatePasswordComponent } from './login-pages/create-password/create-password.component';
import { ForgotPasswordComponent } from './login-pages/forgot-password/forgot-password.component';
import { LoginComponent } from './login-pages/login/login.component';
import { TwoFactorAuthComponent } from './login-pages/two-factor-auth/two-factor-auth.component';

import { TransactionStatus } from './enums/transaction.status';
import { RoutePermission } from './services/route-permission';
import { URI } from './settings/uri-constants';

import { EnterpriseCashbacksRedeemedComponent } from './enterprise/enterprise-cashbacks-redeemed/enterprise-cashbacks-redeemed.component';
import { RouteResolver } from './services/route.resolver';
import { StorageConstant } from './settings/storage-constants';

const routes: Routes = [
    { path: URI.LOGIN, component: LoginComponent },
    { path: URI.LOGIN_TFA, component: TwoFactorAuthComponent },
    { path: URI.FORGOT_PASSWORD, component: ForgotPasswordComponent },
    { path: URI.CREATE_PASSWORD, component: CreatePasswordComponent },
    { path: URI.PRIVACY_POLICY, component: PrivacyPolicyComponent },
    // { path: URI.TAC_PERSONAL, component: PersonalTermsComponent },
    // { path: URI.TAC_MERCHANT, component: MerchantTermsComponent },
    // { path: URI.TAC_AGENT, component: AgentTermsComponent },
    { path: '', redirectTo: URI.SLASHED_LOGIN, pathMatch: 'full' },
    {
        path: URI.getRoutePath('dashboard'),
        component: DashboardComponent,
        canActivate: [RoutePermission],
    },
    // Manage Users
    { path: URI.SEARCH, component: SearchComponent, canActivate: [RoutePermission] },
    {
        path: URI.getRoutePath('manageUsers'),
        component: ManageComponent,
        canActivate: [RoutePermission],
    },
    { path: URI.ADD_STAFF, component: StaffUserComponent, canActivate: [RoutePermission] },
    { path: URI.ADD_AGENT, component: AgentComponent, canActivate: [RoutePermission] },
    {
        path: URI.ADD_ENTERPRISE,
        component: EnterpriseUserComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.VIEW_PROFILE_SUMMARY,
        component: WalletSummaryComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.ENTERPRISE_PROFILE_SUMMARY,
        component: EnterpriseWalletSummaryComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.VIEW_PROFILE_PERSONAL,
        component: UserPersonalProfileComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.VIEW_PROFILE_MERCHANT,
        component: UserMerchantProfileComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.VIEW_PROFILE_AGENT,
        component: UserAgentProfileComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.VIEW_PROFILE_FILES,
        component: UserProfileFilesComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.VIEW_PROFILE_SETTINGS,
        component: UserProfileSettingsComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.VIEW_USER_TRANSACTIONS,
        component: TransactionsComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.VIEW_ALL_USER_TRANSACTIONS,
        component: AllTransactionsComponent,
        canActivate: [RoutePermission],
    },
    { path: URI.CHANGE_MOBILE, component: ChangeMobileComponent, canActivate: [RoutePermission] },
    { path: URI.VERIFY_OTP, component: VerifyOtpComponent, canActivate: [RoutePermission] },
    { path: URI.VERIFY_USERS, component: VerifyUsersComponent, canActivate: [RoutePermission] },

    // Agent Applications
    {
        path: URI.getRoutePath('adminAgentApplications'),
        pathMatch: 'full',
        redirectTo: URI.getRoutePath('adminAgentApplicationsActive'),
    },
    {
        path: URI.getRoutePath('adminAgentApplicationsActive'),
        component: ActiveApplicationsComponent,
        canActivate: [RoutePermission],
        data: { breadcrumb: 'Active Applications' },
    },
    {
        path: URI.getRoutePath('adminAgentApplicationsDenied'),
        component: DeniedApplicationsComponent,
        canActivate: [RoutePermission],
        data: { breadcrumb: 'Denied Applications' },
    },
    {
        path: URI.getRoutePath('adminAgentApplicationsActiveFiles'),
        component: AgentFilesComponent,
        canActivate: [RoutePermission],
        data: {
            parent: URI.getRoutePath('adminAgentApplicationsActive'),
            breadcrumb: () => {
                const user = JSON.parse(localStorage.getItem(StorageConstant.AGENT_FILES));
                return `${user.firstName} ${user.lastName}'s Files`;
            },
        },
    },
    // Transfer To Bank
    {
        path: URI.getRoutePath('adminTransferToBank'),
        pathMatch: 'full',
        redirectTo: URI.getRoutePath('adminTransferToBankPending'),
    },
    {
        path: URI.getRoutePath('adminTransferToBankPending'),
        component: TransferToBankPendingComponent,
        canActivate: [RoutePermission],
        data: { breadcrumb: 'Pending & Processing' },
    },
    {
        path: URI.getRoutePath('adminTransferToBankProcessed'),
        component: TransferToBankProcessedComponent,
        canActivate: [RoutePermission],
        data: { breadcrumb: 'Processed' },
    },
    {
        path: URI.getRoutePath('adminTransferToBankExportLog'),
        component: TransferToBankLogComponent,
        canActivate: [RoutePermission],
        data: { breadcrumb: 'Export Log' },
    },
    {
        path: URI.getRoutePath('adminTransferToBankManualExport'),
        component: TransferToBankManualExportComponent,
        canActivate: [RoutePermission],
        data: { breadcrumb: 'Manual Export' },
    },
    // Settings
    {
        path: URI.getRoutePath('adminSettings'),
        pathMatch: 'full',
        redirectTo: URI.getRoutePath('adminSettingsFees'),
    },
    // { path: URI.getRoutePath('adminSettingsTCs'), component: TermsAndConditionsComponent, canActivate: [RoutePermission] },
    {
        path: URI.getRoutePath('adminSettingsFees'),
        component: FeesComponent,
        canActivate: [RoutePermission],
        data: { breadcrumb: 'Fees & Earnings' },
    },
    // { path: URI.getRoutePath('adminSettingsLevelsAndLimits'), component: LevelsAndLimitsComponent, canActivate: [RoutePermission] },
    // Enterprise
    {
        path: URI.getRoutePath('enterpriseDashboard'),
        component: EnterpriseDashboardComponent,
        canActivate: [RoutePermission],
        data: {
            breadcrumb: (data: any) => {
                return `${data.businessName} Sales`;
            },
        },
        resolve: {
            businessName: RouteResolver.enterpriseDashboardResolver,
        },
    },
    {
        path: URI.getRoutePath('enterpriseProfile'),
        component: EnterpriseProfileComponent,
        canActivate: [RoutePermission],
        data: { breadcrumb: 'Enterprise Details' },
    },
    {
        path: URI.getRoutePath('enterpriseCashbacksRedeemed'),
        component: EnterpriseCashbacksRedeemedComponent,
        canActivate: [RoutePermission],
        data: { breadcrumb: 'Enterprise Details' },
    },
    // {
    //     path: URI.ENTERPRISE_PROFILE,
    //     component: EnterpriseProfileComponent,
    //     canActivate: [RoutePermission],
    // },
    {
        path: URI.ENTERPRISE_EMPLOYEE,
        component: EnterpriseEmployeesComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.ENTERPRISE_SETTINGS,
        component: EnterpriseSettingsComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.ENTERPRISE_TRANSACTION,
        component: EnterpriseTransactionsComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.ENTERPRISE_CASHBACK_SETTINGS,
        component: EnterpriseCashbackSettingsComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.ENTERPRISE_TRANSACTION_ADMIN,
        component: EnterpriseTransactionsComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.ENTERPRISE_TRANSFER_TO_BANK,
        component: EnterpriseTransferToBankComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.ENTERPRISE_PRODUCT_ORDERS,
        component: EnterpriseProductOrdersComponent,
        canActivate: [RoutePermission],
    },

    //   SMART SAFE
    // { path: URI.SMART_SAFE, component: SmartSafeComponent, canActivate: [RoutePermission] },
    {
        path: URI.getRoutePath('adminSmartSafes'),
        // canActivate: [RoutePermission],
        // component: SmartSafesComponent,
        pathMatch: 'full',
        redirectTo: URI.getRoutePath('adminSmartSafeSummary'),
    },
    {
        path: URI.getRoutePath('adminSmartSafeSummary'),
        component: SafeSummaryComponent,
        canActivate: [RoutePermission],
        data: { breadcrumb: 'Safes', summaryRouteActive: false },
        children: [
            {
                path: URI.getRoutePath('adminSmartSafeTransactionBySafe'),
                component: SafeTransactionDetailsComponent,
                canActivate: [RoutePermission],
                data: {
                    summaryRouteActive: false,
                    breadcrumb: (data: any) => {
                        return data.safeId;
                    },
                },
                resolve: {
                    safeId: RouteResolver.safeTransactionResolver,
                },
                children: [
                    {
                        path: URI.getRoutePath('adminSmartSafeTransactionBySafeByUser'),
                        component: SafeUserDetailsComponent,
                        canActivate: [RoutePermission],
                        data: {
                            summaryRouteActive: false,
                            breadcrumb: (data: any) => {
                                return `${data?.childData?.name}`;
                            },
                        },
                        resolve: {
                            childData: RouteResolver.safeUserTransactionResolver,
                        },
                    },
                ],
            },
            {
                path: URI.getRoutePath('adminSmartSafeTransactionByUser'),
                component: SafeUserDetailsComponent,
                canActivate: [RoutePermission],
                data: {
                    summaryRouteActive: false,
                    breadcrumb: (data: any) => {
                        return `${data?.childData?.name} -> ${data?.childData?.safeId}`;
                    },
                },
                resolve: {
                    childData: RouteResolver.safeUserTransactionResolver,
                },
            },
        ],
    },
    {
        path: URI.getRoutePath('cashInTransitCompany'),
        component: CitCompaniesComponent,
        canActivate: [RoutePermission],
        data: {
            summaryRouteActive: true,
            breadcrumb: 'Cash in Transit',
        },
        children: [
            {
                path: 'new',
                component: CitAddCompanyComponent,
                canActivate: [RoutePermission],
                data: {
                    summaryRouteActive: true,
                    breadcrumb: 'New',
                },
            },
        ],
    },
    {
        path: URI.getRoutePath('adminSmartSafeConfiguration'),
        component: SafesConfigurationComponent,
        canActivate: [RoutePermission],
        data: {
            summaryRouteActive: true,
            breadcrumb: 'Configuration',
        },
    },
    {
        path: URI.getRoutePath('adminSmartSafeEvents'),
        component: SafeEventsComponent,
        canActivate: [RoutePermission],
        data: {
            summaryRouteActive: true,
            breadcrumb: 'Events',
        },
    },
    //   TRANSFER FROM BANK
    {
        path: URI.getRoutePath('adminEFTCashIn'),
        component: EftCashInComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.getRoutePath('adminEFTCashInPending'),
        component: EftCashInDataComponent,
        data: { transactionStatus: TransactionStatus.pending },
        canActivate: [RoutePermission],
    },
    {
        path: URI.getRoutePath('adminEFTCashInReceived'),
        component: EftCashInDataComponent,
        data: { transactionStatus: TransactionStatus.success },
        canActivate: [RoutePermission],
    },
    {
        path: URI.getRoutePath('adminEFTCashInCancelled'),
        component: EftCashInDataComponent,
        data: { transactionStatus: TransactionStatus.cancelled },
        canActivate: [RoutePermission],
    },
    // GRV
    { path: URI.getRoutePath('adminGRV'), component: GrvComponent, canActivate: [RoutePermission] },
    // BANNERS
    {
        path: URI.getRoutePath('adminBanners'),
        component: BannersComponent,
        canActivate: [RoutePermission],
        data: { bannerCreated: false },
    },
    {
        path: URI.getRoutePath('adminBannersNew'),
        component: NewBannerComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.getRoutePath('adminProductOrders'),
        component: ProductOrdersComponent,
        canActivate: [RoutePermission],
    },
    {
        path: URI.getRoutePath('adminCashbackReport'),
        component: CashbackReportComponent,
        canActivate: [RoutePermission],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
