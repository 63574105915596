import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Bank } from 'src/app/model/bank';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';
declare let $: any;

@Component({
    selector: 'app-user-bank-data',
    templateUrl: './user-bank-data.component.html',
    styleUrls: ['./user-bank-data.component.css'],
})
export class UserBankDataComponent implements OnInit, OnChanges {
    edit = false;
    invalidForm = false;
    form: FormGroup;
    accountTypeDropdown: string[] = [];
    bankList: Bank[] = [];
    bankNameList: string[] = [];
    @Input() bank: Bank = new Bank();
    @Input() hideHeader = false;
    @Input() hideEdit = false;
    @Output() update = new EventEmitter<FormGroup>();

    constructor(
        private commonService: CommonService,
        private formBuilder: FormBuilder,
        private storageService: StorageService,
    ) {
        this.bank = new Bank();

        this.form = this.formBuilder.group({
            userRoleId: [null],
            bankId: [this.bank.bankId || null],
            bankName: [this.bank.bankName || ''],
            bankCode: [this.bank.bankCode || '', Validators.maxLength(10)],
            branchCode: [this.bank.branchCode || ''],
            bankLocation: [this.bank.bankLocation || '', Validators.maxLength(35)],
            accountNumber: [this.bank.accountNumber || '', Validators.maxLength(20)],
            accountHolderName: [this.bank.accountHolderName || '', Validators.maxLength(70)],
            accountType: [this.bank.accountType || ''],
            bankDetailId: [this.bank.bankDetailId || ''],
        });

        if (this.storageService.accountTypeDropdown.value) {
            this.accountTypeDropdown = this.storageService.accountTypeDropdown.value;
        } else {
            this.commonService.getBankAccountType().subscribe((res) => {
                this.accountTypeDropdown = res.data;
                this.storageService.accountTypeDropdown.next(this.accountTypeDropdown);
            });
        }

        if (this.storageService.bankList.value) {
            this.bankList = this.storageService.bankList.value;
            this.bankNameList = this.storageService.bankNameList.value;
        } else {
            this.commonService.getBanks().subscribe((res) => {
                for (const bank of res) {
                    this.bankNameList.push(bank.bankName);
                }
                this.bankList = res;
                this.storageService.bankList.next(this.bankList);
                this.storageService.bankNameList.next(this.bankNameList);
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.bank.currentValue !== changes.bank.previousValue) {
            this.setForm(changes.bank.currentValue);
        }
    }

    ngOnInit() {
        this.form.get('bankName').valueChanges.subscribe((newVal) => {
            this.setBranchCode(newVal);
            this.setBankCode(newVal);
        });

        if (this.hideHeader) {
            const element = document.getElementsByName('bank-model');
            element.forEach((p) => {
                p.classList.add('modify-padding');
            });
        }
    }

    setForm(bank: Bank) {
        this.form.get('bankId').setValue(bank.bankId);
        this.form.get('bankName').setValue(bank.bankName);
        this.form.get('bankCode').setValue(bank.bankCode);
        this.form.get('branchCode').setValue(bank.branchCode);
        this.form.get('bankLocation').setValue(bank.bankLocation);
        this.form.get('accountNumber').setValue(bank.accountNumber);
        this.form.get('accountHolderName').setValue(bank.accountHolderName);
        this.form.get('accountType').setValue(bank.accountType);
        this.form.get('bankDetailId').setValue(bank.bankDetailId);
    }

    changeView(event: any) {
        this.edit = !this.edit;
        this.setForm(this.bank);
    }

    setBranchCode(bankName: string) {
        if (bankName !== '') {
            for (const bank of this.bankList) {
                if (bank.bankName === bankName) {
                    this.form.get('branchCode').setValue(bank.branchCode);
                }
            }
        }
    }

    setBankCode(bankName: string) {
        if (bankName !== '') {
            for (const bank of this.bankList) {
                if (bank.bankName === bankName) {
                    this.form.get('bankCode').setValue(bank.bankCode);
                }
            }
        }
    }

    submit() {
        if (this.form.valid) {
            this.edit = false;
            this.update.emit(this.form);
        } else {
            this.invalidForm = true;
        }
    }
}
