import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { Role } from 'src/app/enums/role';
import { TransactionStatus } from 'src/app/enums/transaction.status';
import { TransactionType } from 'src/app/enums/transaction.type';
import { Pagination } from 'src/app/model/Pagination';
import { Bank } from 'src/app/model/bank';
import { Transaction } from 'src/app/model/transaction';
import { TransactionLog } from 'src/app/model/transaction.log';
import { TransferToBank } from 'src/app/model/transfer-to-bank';
import { CommonService } from 'src/app/services/common.service';
import { CommonUtil } from 'src/app/services/common.util';
import { ModalService } from 'src/app/services/modal.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { AppConstants } from 'src/app/settings/app-constants';
import { MessageConstant } from 'src/app/settings/message.constant';
import { FormUtils } from 'src/app/utils/form.utils';

@Component({
    selector: 'app-transfer-to-bank-pending',
    templateUrl: './transfer-to-bank-pending.component.html',
    styleUrls: ['./transfer-to-bank-pending.component.css'],
})
export class TransferToBankPendingComponent implements OnInit {
    amountFormat = AppConstants.AMOUNT_FORMAT;
    isLoading = false;
    display = false;
    displayView = false;
    displayUpdate = 'none';
    displaySuccess = false;
    sageFile = '';
    todayDate = '';
    rows = 0;
    totalAmount = 0;
    id: string;
    searchItems: string[] = [];
    itemList = ['role', 'status'];
    accountTypeDropdown: string[] = [Role.enterprise, Role.business, Role.personal];
    transactionStatusDropdown: string[] = [
        TransactionStatus.pending,
        TransactionStatus.processing,
        TransactionStatus.onhold,
    ];
    updateStatusDropdown: string[] = [
        TransactionStatus.pending,
        TransactionStatus.cancelled,
        TransactionStatus.onhold,
    ];
    form: FormGroup;
    updateForm: FormGroup;
    transferWithoutNetcashForm: FormGroup;
    TTB: TransferToBank;
    transactionList: Array<Transaction>;
    transaction: Transaction = new Transaction();
    pagination = new Pagination<Transaction>();
    showOptions = false;
    search = false;
    progressWidth = 25;
    ttbUpdateModalId = 'ttbUpdateModal';
    netcashEnabled = true;
    formUtils = FormUtils;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private commonService: CommonService,
        private transactionService: TransactionService,
        private datePipe: DatePipe,
        public commonUtil: CommonUtil,
        private modalService: ModalService,
        private toasterService: ToastrService,
    ) {
        const date = new Date();
        this.todayDate = this.datePipe.transform(date, 'yyyy-MM-dd');

        this.form = this.formBuilder.group({
            text: [''],
            start: [''],
            length: [''],
            userId: [''],
            role: [''],
            status: [''],
            transactionTypeList: [[TransactionType.transfer_to_bank]],
            transactionStatus: [
                [TransactionStatus.pending, TransactionStatus.processing, TransactionStatus.onhold],
            ],
        });

        this.updateForm = this.formBuilder.group({
            status: [''],
            manualChangeReason: ['', Validators.required],
            transactionStatus: [TransactionStatus.success],
            transactionCode: [''],
        });
    }

    ngOnInit() {
        this.getData();
    }

    closeModel(event: any) {
        this.display = false;
        this.displaySuccess = false;
    }

    getData() {
        this.search = false;
        this.isLoading = true;
        this.form.get('start').setValue(this.pagination.start);
        this.form.get('length').setValue(this.pagination.length);
        this.setProgressWidth(50);
        this.addTransactionStatus();
        this.commonService.getUserTransactionHistory(this.form).subscribe(
            (res) => {
                this.setProgressWidth(100);
                setTimeout(() => {
                    this.transactionList = res.data;
                    this.pagination.data = res.data;
                    this.TTB = res.settlementData;
                    this.netcashEnabled = res.settlementData?.netcashEnabled;
                    this.pagination.total = !res.total ? 0 : res.total;
                    this.isLoading = false;
                    this.search = true;
                    this.progressWidth = 25;
                    this.rows = 0;
                    if (this.transactionList) {
                        for (const txn of this.transactionList) {
                            if (txn.status === TransactionStatus.pending.toString()) {
                                this.rows = this.rows + 1;
                            }
                            if (this.transactionService.txnNotOngoing(txn.status)) {
                                txn.processing = false;
                            } else {
                                txn.processing = true;
                            }
                        }
                    }
                }, 350);

                this.searchItems = [];
                for (const item of this.itemList) {
                    if (this.form.get(item).value !== '') {
                        this.searchItems.push(this.form.get(item).value);
                    }
                }
            },
            (error) => {
                this.isLoading = false;
            },
        );
    }

    addTransactionStatus() {
        const val: string = this.form.get('status').value;
        if (val.length) {
            this.form.get('transactionStatus').setValue([val]);
        } else {
            this.form
                .get('transactionStatus')
                .setValue([
                    TransactionStatus.pending,
                    TransactionStatus.processing,
                    TransactionStatus.onhold,
                ]);
        }
    }

    setProgressWidth(i: number) {
        setTimeout(() => {
            this.progressWidth = i;
        }, 300);
    }

    searchUsers(text: string) {
        this.pagination.text = text;
        this.getData();
    }

    removeSearchItem(value: string) {
        for (const item of this.itemList) {
            if (this.form.get(item).value === value) {
                this.form.get(item).setValue('');
                this.getData();
                break;
            }
        }
    }

    clearText() {
        this.form.get('text').setValue('');
    }

    receiveFilter(data: { name: string; value: number }) {
        this.transactionList = this.pagination.filterData(data);
    }

    changeLength(len: number) {
        this.pagination.length = len;
        this.pagination.start = 0;
        this.getData();
    }

    changePage(page: number) {
        this.pagination.changePage(page);
        this.getData();
    }

    toggleOption() {
        this.showOptions = !this.showOptions;
    }

    openViewModel() {
        this.displayView = true;
    }

    closeViewModel() {
        this.displayView = false;
    }

    closeUpdateModal() {
        this.modalService.close(this.ttbUpdateModalId);
    }

    resetUpdateForm() {
        this.updateForm.reset();
    }

    action(event: { type: string; value: Transaction }) {
        this.transaction = event.value;
        this.updateForm.get('status').setValue(this.transaction.status);
        this.updateForm.get('transactionCode').setValue(this.transaction.transactionCode);

        if (!this.transaction.bankDetails) {
            this.transaction.bankDetails = new Bank();
        }

        if (!this.transaction.transactionLog) {
            this.transaction.transactionLog = new Array<TransactionLog>();
        }

        switch (event.type) {
            case 'view':
                this.openViewModel();
                break;
            case 'update':
                this.modalService.open(this.ttbUpdateModalId);
                this.updateForm.get('manualChangeReason').setValue('');
                break;
        }
    }

    get controls(): any {
        return this.form.controls;
    }

    submit() {
        this.getData();
    }

    update() {
        if (this.updateForm.invalid) {
            this.formUtils.validateAllFormFields(this.updateForm);
            return false;
        }

        if (!this.netcashEnabled) {
            this.updateForm.get('status').setValue(TransactionStatus.cancelled);
        }

        this.isLoading = true;
        this.closeUpdateModal();
        this.closeModel(true);

        this.transactionService
            .updateTransferToBank(this.updateForm.value, this.transaction.settlementRequestId)
            .subscribe({
                next: (res) => {
                    this.isLoading = false;
                    this.getData();

                    if (res.success) {
                        this.toasterService.success(
                            MessageConstant.BANK_TRANSFER_UPDATE_REQUEST_SUCCESS,
                            'Success',
                        );
                    } else {
                        this.display = true;
                        if (res.status) {
                            this.toasterService.error(res.status.message, 'Error');
                        }
                    }
                },
                error: (err) => {
                    this.isLoading = false;
                    this.display = true;
                    this.getData();
                },
            });
    }

    processWithoutNetcash() {
        if (this.updateForm.invalid) {
            this.formUtils.validateAllFormFields(this.updateForm);
            return false;
        }

        this.closeUpdateModal();
        this.isLoading = true;

        this.transactionService.transferWithoutNetcash(this.updateForm.value).subscribe({
            next: (res) => {
                this.isLoading = false;

                if (res.success && !res?.status?.message) {
                    this.getData();
                    this.toasterService.success(
                        MessageConstant.BANK_TRANSFER_WITHOUT_NETCASH_SUCCESSFUL,
                        'Success',
                    );
                } else {
                    this.display = true;
                    if (res.status) {
                        this.toasterService.error(res.status.message, 'Error');
                    }
                }

            },
            error: (err) => {
                this.isLoading = false;
                this.display = true;
                //this.getData();
            },
        });
    }
}
