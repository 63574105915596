import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Observable } from 'rxjs/internal/Observable';
import { CashbackSetting } from '../model/cashback.setting';
import { Employee } from '../model/employee';
import { Enterprise } from '../model/enterprise';
import { GenericResponse } from '../model/generic.response';
import { ProductOrder } from '../model/product.order';
import { Transaction } from '../model/transaction';
import { AppSettings } from '../settings/app-settings';

@Injectable({ providedIn: 'root' })
export class EnterpriseService {
    constructor(private http: HttpClient) {}

    addEnterprise(form: FormGroup) {
        return this.http.post<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.ADD_ENTERPRISE,
            JSON.stringify(form.value),
        );
    }

    addEnterpriseStaff(form: FormGroup) {
        return this.http.post<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.ADD_ENTERPRISE_STAFF,
            JSON.stringify(form.value),
        );
    }

    updateEnterpriseStaff(form: FormGroup) {
        return this.http.post<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.UPDATE_ENTERPRISE_STAFF,
            JSON.stringify(form.value),
        );
    }

    updateEnterpriseStaffPin(form: FormGroup) {
        return this.http.post<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + AppSettings.UPDATE_ENTERPRISE_STAFF_PIN,
            JSON.stringify(form.value),
        );
    }

    updateEnterpriseContact(form: FormGroup, userId: string) {
        const path = AppSettings.UPDATE_ENTERPRISE_CONTACT.replace('@1@', userId);

        return this.http.patch<GenericResponse<any>>(
            AppSettings.API_ENDPOINT + path,
            JSON.stringify(form.value),
        );
    }

    deactivateEnterpriseStaff(userId: string, body: any) {
        const path = AppSettings.DEACTIVATE_ENTERPRISE_STAFF.replace('@1@', userId);

        return this.http.patch<GenericResponse<any>>(AppSettings.API_ENDPOINT + path, body);
    }

    getEnterpriseProfile(userId: string): Observable<GenericResponse<Enterprise>> {
        const path = AppSettings.GET_ENTERPRISE_PROFILE.replace('@1@', userId);
        return this.http.get<GenericResponse<Enterprise>>(AppSettings.API_ENDPOINT + path);
    }

    // getEnterpriseProfile(userId: string): Observable<GenericResponse<Enterprise>> {
    //     const path = AppSettings.GET_ENTERPRISE_PROFILE.replace('@1@', userId);
    //     return this.http
    //         .get<GenericResponse<Enterprise>>(AppSettings.API_ENDPOINT + path)
    //         .pipe(tap((_) => console.log(_.data)));
    // }

    // /**
    //  * Handle Http operation that failed.
    //  * Let the app continue.
    //  *
    //  * @param operation - name of the operation that failed
    //  * @param result - optional value to return as the observable result
    //  */
    // private handleError<T>(operation = 'operation', result?: T) {
    //     return (error: any): Observable<T> => {
    //         // TODO: send the error to remote logging infrastructure
    //         console.error(error); // log to console instead

    //         // TODO: better job of transforming error for user consumption
    //         console.log(`${operation} failed: ${error.message}`);

    //         // Let the app keep running by returning an empty result.
    //         return of(result as T);
    //     };
    // }

    getEnterpriseEmployees(form: FormGroup, userId: string) {
        const path = AppSettings.GET_ENTERPRISE_EMPLOYEES.replace('@1@', userId);
        return this.http.post<GenericResponse<Employee[]>>(
            AppSettings.API_ENDPOINT + path,
            JSON.stringify(form.value),
        );
    }

    getEmployeeTransactionHistory(form: FormGroup) {
        return this.http.post<GenericResponse<Transaction[]>>(
            AppSettings.API_ENDPOINT + AppSettings.ENTERPRISE_TRANSACTION,
            JSON.stringify(form.value),
        );
    }

    addCashbackSettings(form: FormGroup) {
        return this.http.post<GenericResponse<CashbackSetting[]>>(
            AppSettings.API_ENDPOINT + AppSettings.ADD_ENTERPRISE_CASHBACK_SETTINGS,
            JSON.stringify(form),
        );
    }

    getCashbackSettings(userRoleId: string) {
        const path = AppSettings.GET_ENTERPRISE_CASHBACK_SETTINGS.replace('@1@', userRoleId);
        return this.http.get<GenericResponse<CashbackSetting[]>>(AppSettings.API_ENDPOINT + path);
    }

    updateCashbackSettings(form: FormGroup[]) {
        return this.http.patch<GenericResponse<CashbackSetting[]>>(
            AppSettings.API_ENDPOINT + AppSettings.UPDATE_ENTERPRISE_CASHBACK_SETTINGS,
            JSON.stringify(form),
        );
    }

    deleteCashbackSettings(cashBackSettingsId: string) {
        const path = AppSettings.DELETE_ENTERPRISE_CASHBACK_SETTINGS.replace(
            '@1@',
            cashBackSettingsId,
        );
        return this.http.patch<GenericResponse<CashbackSetting[]>>(
            AppSettings.API_ENDPOINT + path,
            {},
        );
    }

    createTransferToBank(body: any) {
        return this.http.post<GenericResponse<CashbackSetting[]>>(
            AppSettings.TRANSACTION_API_ENDPOINT + AppSettings.CREATE_TRANSFER_TO_BANK_REQUEST,
            body,
        );
    }

    refund(transactionId: string) {
        const path = AppSettings.REFUND_TRANSACTION.replace('@1@', transactionId);
        return this.http.post<GenericResponse<any>>(AppSettings.API_ENDPOINT + path, {
            mode: 'portal',
        });
    }

    getProductOrders(form: FormGroup) {
        return this.http.post<GenericResponse<ProductOrder[]>>(
            AppSettings.API_ENDPOINT + AppSettings.ENTERPRISE_PRODUCT_ORDERS,
            JSON.stringify(form.value),
        );
    }

    fullFillProductOrder(productOrderId: string, body: any) {
        const path = AppSettings.FULFILL_ENTERPRISE_PRODUCT_ORDER.replace('@1@', productOrderId);

        return this.http.patch<GenericResponse<any>>(AppSettings.API_ENDPOINT + path, body);
    }
}
