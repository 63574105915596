import { ScrollingModule } from '@angular/cdk/scrolling';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './app.material';

// Syncfusion Modules and Services
import {
    AccumulationChartModule,
    AccumulationDataLabelService,
    AccumulationLegendService,
    ChartModule,
    ColumnSeriesService,
    DataLabelService,
    DateTimeCategoryService,
    DateTimeService,
    LegendService,
    LineSeriesService,
    PieSeriesService,
    TooltipService,
} from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { ProgressBarModule } from '@syncfusion/ej2-angular-progressbar';

import { InlineStylesCSPModule } from './inline-styles-csp/inline-styles-csp.module';
import { LoaderService } from './services/loader.service';

import { AgentTermsComponent } from './admin/admin-settings/agent-terms/agent-terms.component';
import { FeesComponent } from './admin/admin-settings/fees/fees.component';
import { LevelsAndLimitsComponent } from './admin/admin-settings/levels-and-limits/levels-and-limits.component';
import { MerchantTermsComponent } from './admin/admin-settings/merchant-terms/merchant-terms.component';
import { PersonalTermsComponent } from './admin/admin-settings/personal-terms/personal-terms.component';
import { PrivacyPolicyComponent } from './admin/admin-settings/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './admin/admin-settings/terms-and-conditions/terms-and-conditions.component';
import { ActiveApplicationsComponent } from './admin/agent-applications/active-applications/active-applications.component';
import { AgentFilesComponent } from './admin/agent-applications/agent-files/agent-files.component';
import { AgentApplicationsComponent } from './admin/agent-applications/applications/agent-applications.component';
import { DeniedApplicationsComponent } from './admin/agent-applications/denied-applications/denied-applications.component';
import { BannersComponent } from './admin/banner/index/banners.component';
import { NewBannerComponent } from './admin/banner/new-banner/new-banner.component';
import { CashbackReportComponent } from './admin/cashback-report/cashback-report.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { EftCashInDataComponent } from './admin/eft-cash-in/eft-cash-in-data/eft-cash-in-data.component';
import { EftCashInComponent } from './admin/eft-cash-in/eft-cash-in/eft-cash-in.component';
import { GrvComponent } from './admin/grv/grv.component';
import { AgentComponent } from './admin/manage-users/add-user/agent/agent.component';
import { BankComponent } from './admin/manage-users/add-user/bank/bank.component';
import { EnterpriseUserComponent } from './admin/manage-users/add-user/enterprise-user/enterprise-user.component';
import { StaffUserComponent } from './admin/manage-users/add-user/staff-user/staff-user.component';
import { ChangeMobileComponent } from './admin/manage-users/change-mobile/change-mobile.component';
import { ManageComponent } from './admin/manage-users/manage/manage.component';
import { SearchComponent } from './admin/manage-users/search/search.component';
import { VerifyOtpComponent } from './admin/manage-users/verify-otp/verify-otp.component';
import { VerifyUsersComponent } from './admin/manage-users/verify-users/verify-users.component';
import { EditLinksComponent } from './admin/manage-users/view/common/edit-links/edit-links.component';
import { EditPencilComponent } from './admin/manage-users/view/common/edit-pencil/edit-pencil.component';
import { UserAddressComponent } from './admin/manage-users/view/common/user-address/user-address.component';
import { UserBankDataComponent } from './admin/manage-users/view/common/user-bank-data/user-bank-data.component';
import { UserProfileDataComponent } from './admin/manage-users/view/common/user-profile-data/user-profile-data.component';
import { UserWalletDataComponent } from './admin/manage-users/view/common/user-wallet-data/user-wallet-data.component';
import { TransactionsComponent } from './admin/manage-users/view/transactions/transactions.component';
import { UserAgentProfileComponent } from './admin/manage-users/view/user-agent-profile/user-agent-profile.component';
import { UserMerchantProfileComponent } from './admin/manage-users/view/user-merchant-profile/user-merchant-profile.component';
import { UserPersonalProfileComponent } from './admin/manage-users/view/user-personal-profile/user-personal-profile.component';
import { UserProfileFilesComponent } from './admin/manage-users/view/user-profile-files/user-profile-files.component';
import { UserProfileSettingsComponent } from './admin/manage-users/view/user-profile-settings/user-profile-settings.component';
import { WalletSummaryComponent } from './admin/manage-users/view/wallet-summary/wallet-summary.component';
import { ProductOrdersComponent } from './admin/product-orders/product-orders.component';
import { SmartSafeComponent } from './admin/smart-safe/smart-safe/smart-safe.component';
import { CitAddCompanyComponent } from './admin/smart-safes/cash-in-transit/cit-add-company/cit-add-company.component';
import { CitCompaniesComponent } from './admin/smart-safes/cash-in-transit/cit-companies/cit-companies.component';
import { SafeEventsComponent } from './admin/smart-safes/safe-events/safe-events.component';
import { SafeSummaryComponent } from './admin/smart-safes/safe-summary/index/safe-summary.component';
import { SafeSummaryDetailsComponent } from './admin/smart-safes/safe-summary/safe-summary-details/safe-summary-details.component';
import { SafeTransactionDetailsComponent } from './admin/smart-safes/safe-summary/safe-transaction-details/safe-transaction-details.component';
import { SafeUserDetailsComponent } from './admin/smart-safes/safe-summary/safe-user-details/safe-user-details.component';
import { SafesConfigurationComponent } from './admin/smart-safes/safes-configuration/index/safes-configuration.component';
import { SmartSafeModalComponent } from './admin/smart-safes/safes-configuration/smart-safe-modal/smart-safe-modal.component';
import { SmartSafesComponent } from './admin/smart-safes/smart-safes/smart-safes.component';
import { AllTransactionsComponent } from './admin/transactions/all-transactions/all-transactions.component';
import { TransferToBankLogComponent } from './admin/transfer-to-bank/transfer-to-bank-log/transfer-to-bank-log.component';
import { TransferToBankManualExportComponent } from './admin/transfer-to-bank/transfer-to-bank-manual-export/transfer-to-bank-manual-export.component';
import { TransferToBankPendingComponent } from './admin/transfer-to-bank/transfer-to-bank-pending/transfer-to-bank-pending.component';
import { TransferToBankProcessedComponent } from './admin/transfer-to-bank/transfer-to-bank-processed/transfer-to-bank-processed.component';
import { AppComponent } from './app.component';
import { AccountStatusComponent } from './common-components/account-status/account-status.component';
import { AgentApplicationLinkComponent } from './common-components/agent-application-link/agent-application-link.component';
import { AgentFeesComponent } from './common-components/agent-fees/agent-fees.component';
import { AlertComponent } from './common-components/alert/alert.component';
import { BreadcrumbComponent } from './common-components/breadcrumb/breadcrumb.component';
import { BusinessFeesComponent } from './common-components/business-fees/business-fees.component';
import { ContentTransitionComponent } from './common-components/content-transition/content-transition.component';
import { DropdownIconComponent } from './common-components/dropdown-icon/dropdown-icon.component';
import { EftCashInLinkComponent } from './common-components/eft-cash-in-link/eft-cash-in-link.component';
import { EnterpriseFeesComponent } from './common-components/enterprise-fees/enterprise-fees.component';
import { ErrorModelComponent } from './common-components/error-model/error-model.component';
import { ErrorPopupComponent } from './common-components/error-popup/error-popup.component';
import { FieldErrorDisplayComponent } from './common-components/field-error-display/field-error-display.component';
import { HeaderComponent } from './common-components/header/header.component';
import { LinksComponent } from './common-components/links/links.component';
import { LoaderComponent } from './common-components/loader/loader.component';
import { ModalComponent } from './common-components/modal/modal.component';
import { NavigationComponent } from './common-components/navigation/navigation.component';
import { PersonalUserFeesComponent } from './common-components/personal-user-fees/personal-user-fees.component';
import { SafesHeaderComponent } from './common-components/safes-header/safes-header.component';
import { SafesLinkComponent } from './common-components/safes-link/safes-link.component';
import { SalesDashboardComponent } from './common-components/sales-dashboard/sales-dashboard.component';
import { SelectBoxComponent } from './common-components/select-box/select-box.component';
import { SelectTypeComponent } from './common-components/select-type/select-type.component';
import { SettingsLinkComponent } from './common-components/settings-link/settings-link.component';
import { SuccessModelComponent } from './common-components/success-model/success-model.component';
import { TransactionViewModalComponent } from './common-components/transaction-view-modal/transaction-view-modal.component';
import { TransferToBankLinksComponent } from './common-components/transfer-to-bank-links/transfer-to-bank-links.component';
import { CompanyComponent } from './company/company.component';
import { ExportExcelDirective } from './directives/export-excel.directive';
import { appFloatOnlyDirective } from './directives/float-only.directive';
import { IntegerOnlyDirective } from './directives/integer-only.directive';
import { EnterpriseLinksComponent } from './enterprise/common/enterprise-links/enterprise-links.component';
import { EnterpriseNavigationComponent } from './enterprise/common/enterprise-navigation/enterprise-navigation.component';
import { EnterpriseCashbackSettingsComponent } from './enterprise/enterprise-cashback-settings/enterprise-cashback-settings.component';
import { EnterpriseCashbacksRedeemedComponent } from './enterprise/enterprise-cashbacks-redeemed/enterprise-cashbacks-redeemed.component';
import { EnterpriseDashboardComponent } from './enterprise/enterprise-dashboard/enterprise-dashboard.component';
import { EnterpriseEmployeesComponent } from './enterprise/enterprise-employees/enterprise-employees.component';
import { EnterpriseHeaderComponent } from './enterprise/enterprise-header/enterprise-header.component';
import { EnterpriseProductOrdersComponent } from './enterprise/enterprise-product-orders/enterprise-product-orders.component';
import { EnterpriseProfileComponent } from './enterprise/enterprise-profile/enterprise-profile.component';
import { EnterpriseSettingsComponent } from './enterprise/enterprise-settings/enterprise-settings.component';
import { EnterpriseTransactionsComponent } from './enterprise/enterprise-transactions/enterprise-transactions.component';
import { EnterpriseTransferToBankComponent } from './enterprise/enterprise-transfer-to-bank/enterprise-transfer-to-bank.component';
import { EnterpriseWalletSummaryComponent } from './enterprise/enterprise-wallet-summary/enterprise-wallet-summary.component';
import { CreatePasswordComponent } from './login-pages/create-password/create-password.component';
import { ForgotPasswordComponent } from './login-pages/forgot-password/forgot-password.component';
import { LoginComponent } from './login-pages/login/login.component';
import { TwoFactorAuthComponent } from './login-pages/two-factor-auth/two-factor-auth.component';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { HumanizePipe } from './pipes/humanize.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { HttpRequestInterceptor } from './services/http-request-interceptor';
import { FilterArrowComponent } from './table-component/filter-arrow/filter-arrow';
import { LastChildAdminBannerComponent } from './table-component/last-child-admin-banner/last-child-admin-banner.component';
import { LastChildAdminTtbComponent } from './table-component/last-child-admin-ttb/last-child-admin-ttb.component';
import { LastChildAgentComponent } from './table-component/last-child-agent/last-child-agent.component';
import { LastChildEnterpriseCashbackSettingsComponent } from './table-component/last-child-enterprise-cashback-settings/last-child-enterprise-cashback-settings.component';
import { LastChildEnterpriseTtbComponent } from './table-component/last-child-enterprise-ttb/last-child-enterprise-ttb.component';
import { LastChildEnterpriseComponent } from './table-component/last-child-enterprise/last-child-enterprise.component';
import { LastChildGrvComponent } from './table-component/last-child-grv/last-child-grv.component';
import { LastChildSmartSafeComponent } from './table-component/last-child-smart-safe/last-child-smart-safe.component';
import { LastChildTransactionComponent } from './table-component/last-child-transaction/last-child-transaction.component';
import { LastChildVerifyUsersComponent } from './table-component/last-child-verify-users/last-child-verify-users.component';
import { LastChildComponent } from './table-component/last-child/last-child';
import { PageLengthDropdownComponent } from './table-component/page-length-dropdown/page-length-dropdown';
import { PageNavigationComponent } from './table-component/page-navigation/page-navigation';
import { ProductOrderDetailsComponent } from './table-component/product-order-details/product-order-details.component';
import { ProductOrdersBodyComponent } from './table-component/product-orders-body/product-orders-body.component';
import { TransactionBodyComponent } from './table-component/transaction-body/transaction-body.component';
import { UserStatusComponent } from './table-component/user-status/user-status.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LinksComponent,
        HeaderComponent,
        DashboardComponent,
        NavigationComponent,
        DropdownIconComponent,
        SelectBoxComponent,
        ContentTransitionComponent,
        FilterArrowComponent,
        LastChildComponent,
        PageLengthDropdownComponent,
        PageNavigationComponent,
        UserStatusComponent,
        SelectTypeComponent,
        LoaderComponent,
        ErrorPopupComponent,
        StaffUserComponent,
        AgentComponent,
        UserProfileDataComponent,
        UserWalletDataComponent,
        UserBankDataComponent,
        EditPencilComponent,
        EditLinksComponent,
        UserMerchantProfileComponent,
        UserPersonalProfileComponent,
        UserProfileFilesComponent,
        UserProfileSettingsComponent,
        SearchComponent,
        ChangeMobileComponent,
        VerifyOtpComponent,
        ManageComponent,
        ForgotPasswordComponent,
        PrivacyPolicyComponent,
        AgentApplicationsComponent,
        ActiveApplicationsComponent,
        DeniedApplicationsComponent,
        LastChildAgentComponent,
        AgentApplicationLinkComponent,
        LevelsAndLimitsComponent,
        FeesComponent,
        TermsAndConditionsComponent,
        SettingsLinkComponent,
        AgentFilesComponent,
        PersonalTermsComponent,
        MerchantTermsComponent,
        AgentTermsComponent,
        VerifyUsersComponent,
        LastChildVerifyUsersComponent,
        AccountStatusComponent,
        TransactionsComponent,
        LastChildTransactionComponent,
        EnterpriseUserComponent,
        BankComponent,
        CreatePasswordComponent,
        EnterpriseNavigationComponent,
        EnterpriseProfileComponent,
        EnterpriseDashboardComponent,
        EnterpriseEmployeesComponent,
        EnterpriseTransactionsComponent,
        EnterpriseLinksComponent,
        LastChildEnterpriseComponent,
        EnterpriseCashbackSettingsComponent,
        LastChildEnterpriseCashbackSettingsComponent,
        HumanizePipe,
        CurrencyFormatPipe,
        ReplacePipe,
        appFloatOnlyDirective,
        IntegerOnlyDirective,
        ExportExcelDirective,
        ErrorModelComponent,
        SuccessModelComponent,
        EnterpriseSettingsComponent,
        TwoFactorAuthComponent,
        SmartSafeComponent,
        LastChildSmartSafeComponent,
        AllTransactionsComponent,
        WalletSummaryComponent,
        EnterpriseWalletSummaryComponent,
        TransactionViewModalComponent,
        UserAgentProfileComponent,
        EnterpriseTransferToBankComponent,
        TransferToBankProcessedComponent,
        TransferToBankPendingComponent,
        TransferToBankManualExportComponent,
        TransferToBankLogComponent,
        TransferToBankLinksComponent,
        LastChildAdminTtbComponent,
        LastChildEnterpriseTtbComponent,
        EftCashInComponent,
        EftCashInDataComponent,
        EftCashInLinkComponent,
        TransactionBodyComponent,
        GrvComponent,
        LastChildGrvComponent,
        FieldErrorDisplayComponent,
        ModalComponent,
        CompanyComponent,
        BannersComponent,
        LastChildAdminBannerComponent,
        NewBannerComponent,
        AlertComponent,
        SmartSafesComponent,
        SafeSummaryComponent,
        SafeSummaryDetailsComponent,
        SafesLinkComponent,
        SafesConfigurationComponent,
        SafeEventsComponent,
        SafesHeaderComponent,
        SmartSafeModalComponent,
        SafeSummaryDetailsComponent,
        SafeTransactionDetailsComponent,
        SafeUserDetailsComponent,
        AgentFeesComponent,
        BusinessFeesComponent,
        EnterpriseFeesComponent,
        PersonalUserFeesComponent,
        EnterpriseProductOrdersComponent,
        ProductOrdersBodyComponent,
        ProductOrdersComponent,
        ProductOrderDetailsComponent,
        SalesDashboardComponent,
        EnterpriseHeaderComponent,
        CitCompaniesComponent,
        CitAddCompanyComponent,
        BreadcrumbComponent,
        UserAddressComponent,
        CashbackReportComponent,
        EnterpriseCashbacksRedeemedComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MaterialModule,
        CKEditorModule,
        ScrollingModule,
        NgSelectModule,
        InlineStylesCSPModule,
        ToastrModule.forRoot({
            timeOut: 6000,
            // disableTimeOut: true,
            preventDuplicates: true,
            enableHtml: true,
            positionClass: 'toast-top-right',
        }),
        LoggerModule.forRoot({
            serverLoggingUrl: '/api/logs',
            level: NgxLoggerLevel.DEBUG,
            serverLogLevel: NgxLoggerLevel.ERROR,
        }),

        // Syncfusion Modules
        DashboardLayoutModule,
        ChartModule,
        AccumulationChartModule,
        ProgressBarModule,
    ],
    providers: [
        LoaderService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
        DatePipe,
        HumanizePipe,

        // Syncfusion Service Providers
        LineSeriesService,
        DateTimeService,
        DateTimeCategoryService,
        PieSeriesService,
        AccumulationLegendService,
        AccumulationDataLabelService,
        TooltipService,
        DataLabelService,
        LegendService,
        ColumnSeriesService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
