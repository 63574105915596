import { Component, Inject, OnInit } from '@angular/core';
import { EnterpriseService } from 'src/app/services/enterprise.service';

import { DOCUMENT } from '@angular/common';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/internal/Observable';
import { BusinessType } from 'src/app/enums/business.type';
import { Role } from 'src/app/enums/role';
import { VerificationType } from 'src/app/enums/verification.type';
import { Bank } from 'src/app/model/bank';
import { Enterprise } from 'src/app/model/enterprise';
import { GenericResponse } from 'src/app/model/generic.response';
import { UserAddress } from 'src/app/model/user.address';
import { CommonService } from 'src/app/services/common.service';
import { LoginService } from 'src/app/services/login.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppConstants } from 'src/app/settings/app-constants';
import { MessageConstant } from 'src/app/settings/message.constant';

@Component({
    selector: 'app-enterprise-profile',
    templateUrl: './enterprise-profile.component.html',
    styleUrls: ['./enterprise-profile.component.css'],
})
export class EnterpriseProfileComponent implements OnInit {
    amountFormat = AppConstants.AMOUNT_FORMAT;
    dateTimeFormat = AppConstants.DATE_TIME_FORMAT;
    dateFormat = AppConstants.DATE_FORMAT;
    display = false;
    isLoading = false;
    displaySuccess = false;
    sMessage = '';
    role = Role;
    user: Enterprise;
    user$!: Observable<GenericResponse<Enterprise>>;
    id: string;
    form: FormGroup;
    bankForm: FormGroup;
    invalidForm = false;
    eMessage = '';
    emailExist: boolean;
    emailErrorMsg: string;
    mobileErrorMsg: string;
    mobileExist: boolean;
    editEnterprise: boolean;

    constructor(
        private enterpriseService: EnterpriseService,
        public loginService: LoginService,
        private formBuilder: UntypedFormBuilder,
        @Inject(DOCUMENT) private document: Document,
        private commonService: CommonService,
        private storageService: StorageService,
        private toasterService: ToastrService
    ) {
        this.emailExist = false;
        this.mobileExist = false;
        this.editEnterprise = false;
        this.emailErrorMsg = 'The email-id is invalid';
        this.mobileErrorMsg = 'The mobile number is invalid';
        this.user = new Enterprise();
        this.id = this.loginService.sessionUser.value.enterprise.enterpriseId;

        this.form = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', Validators.required],
            mobileNumber: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(11),
                    Validators.minLength(9),
                    Validators.pattern('^[0-9]*$'),
                ],
            ],
        });
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.isLoading = true;
        this.enterpriseService.getEnterpriseProfile(this.id).subscribe(
            (res) => {
                this.user = res.data;
                this.initFields();
                this.storageService.setEnterpriseProfile(this.user);
                this.isLoading = false;
            },
            (err) => {
                this.isLoading = false;
            },
        );
    }

    initFields() {
        if (!this.user.bank) {
            this.user.bank = new Bank();
        }
        if (!this.user.address) {
            this.user.address = new UserAddress();
        }
        this.form.get('firstName').setValue(this.user.primaryContact.firstName);
        this.form.get('lastName').setValue(this.user.primaryContact.lastName);
        this.form.get('email').setValue(this.user.primaryContact.email);
        this.form.get('mobileNumber').setValue(this.user.primaryContact.mobileNumber);
    }

    changeView(event: any, type: string) {
        this.editEnterprise = !this.editEnterprise;
    }

    closeModel(val: boolean) {
        this.display = false;
        this.displaySuccess = false;
    }

    submit() {
        this.invalidForm = this.form.invalid;
        if (this.form.invalid) {
            this.display = true;
            this.eMessage = 'Please fill all required fields.';
            return false;
        }

        this.isLoading = true;

        this.closeModel(true);
        this.enterpriseService.updateEnterpriseContact(this.form, this.id).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    this.changeView('', '');
                    this.getData();
                    this.sMessage = res.status.message;
                    this.displaySuccess = true;
                } else {
                    this.eMessage = res.status.message;
                    this.display = true;
                }
            },
            (error) => {
                this.isLoading = false;
                this.eMessage = error.error.error;
                this.display = true;
            },
        );
    }

    copyText(val: string) {
        const input = this.document.createElement('input');
        this.document.body.appendChild(input);
        if (val) {
            input.value = val;
        } else {
            input.value = 'NA';
        }
        input.select();
        this.document.execCommand('copy');
        input.remove();
    }

    validFileType(file: File) {
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
            return true;
        }
        return false;
    }

    changeProfile(files: any) {
        if (this.validFileType(files[0]) && files[0].size > 0) {
            this.updateFile(BusinessType.business_logo, files[0]);
        }
    }

    updateFile(type: string, file: File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const baseFile = reader.result.toString().split(',')[1];
            this.updateLogo(baseFile, file);
        };
    }

    updateLogo(base64File: string, file: File) {
        const imageForm: FormGroup = this.formBuilder.group({
            fileType: [BusinessType.business_logo],
            verificationType: [VerificationType.business],
            imageValue: [base64File],
            imageName: [file.name],
        });

        this.isLoading = true;
        this.commonService
            .updateLogo(imageForm.value, this.user.userId, this.user.roleId)
            .subscribe(
                (res) => {
                    this.isLoading = false;
                    // this.getData();
                    window.location.reload();
                },
                (err) => {
                    this.isLoading = false;
                },
            );
    }

    updateBank(bank: FormGroup) {
        this.isLoading = true;
        this.closeModel(true);

        bank.get('userRoleId').setValue(this.user.userRoleId);
        this.commonService.updateBank(bank.value, this.user.userId, this.user.roleId).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    this.displaySuccess = true;
                    this.sMessage = 'Bank updated successfully.';
                    this.getData();
                } else {
                    this.eMessage = MessageConstant.BANK_UPDATE_SUCCESS;
                    this.display = true;
                }
            },
            (err) => {
                this.isLoading = false;

                if (err.error.errors) {
                    this.eMessage = err.error.errors[0];
                } else {
                    this.eMessage = err.error.error;
                }
                this.display = true;
            },
        );
    }

    updateAddress(address: FormGroup) {
        this.closeModel(true);
        this.isLoading = true;

        this.commonService.updateAddress(address.value, this.user.userId).subscribe(
            (res) => {
                this.isLoading = false;
                if (res.success) {
                    this.toasterService.success(MessageConstant.ADDRESS_UPDATE_SUCCESS, 'Success');
                    this.getData();
                } else {
                    this.toasterService.error('Failed to update', 'Error');
                }
            },
            (err) => {
                this.isLoading = false;
                //this.toasterService.error(err.message, 'Error');
            },
        );
    }
}
